<template>
  <div>
    <div v-if="showMarquee" @click="handleShowMarquee" class="marquee-box">
      <img class="marquee-img" src="https://osstest.saiyoujiaoyu.com/fe-common/sy-web/fanzha/close-fanzha.png" alt="">
    </div>
    <div v-if="showMarquee" @click="handleLinkDing" class="marquee">
      <p>{{msg}}</p>
      <!-- <div class="scrollArea" v-if="msg">
        <marquee direction="left" scrollamount="4">
          {{ msg }}
        </marquee>
      </div> -->
    </div>
    <van-tabbar style="border-top:0;" :fixed="true" class="footer-tabber" v-model="active">
      <van-tabbar-item @click="toIndex">
        <span>首页</span>
        <template #icon>
          <img src="@img/icon-tabbar-3.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click="show1 = true">
        <span>电话咨询</span>
        <template #icon>
          <img src="@img/icon-tabbar-2.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click="show2 = true">
        <span>在线咨询</span>
        <template #icon>
          <img src="@img/icon-tabbar-1.png" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <van-action-sheet v-model="show1" title="">
      <div class="pd30">
        <a :href="'tel:'+serviceTelLists[0].value">
          <img src="@img/icon-tabbar-4.png" alt="">
          <span>{{ serviceTelLists[0] && serviceTelLists[0].name }}</span>
          <span style="color:#0DD280"> <a >{{serviceTelLists[0].value}}</a></span>
        </a>
        <!-- <a href="tel:010-59897375">
          <img src="@img/icon-tabbar-4.png" alt="">
          <span>{{ serviceTelLists[1] && serviceTelLists[1].name }}</span>
          <span style="color:#0DD280"> <a>010-59897375</a></span>
        </a> -->
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="show2" title="">
      <div class="pd30">
        <div>
          <button @click="$openLink(baseInfo.service.noSignupChatUrl)">{{ serviceTelLists[0] && serviceTelLists[0].name }}</button>
        </div>
        <!-- <div>
          <button @click="$openLink(baseInfo.service.signupChatUrl)">{{ serviceTelLists[1] && serviceTelLists[1].name }}</button>
        </div> -->
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      show2: false,
      active: "",
      baseInfo:{},
      serviceTelLists: [],  // 服务电话列表
      icon: {
        active: this.$utils.VANTURL+"/user-active.png",
        inactive: this.$utils.VANTURL+"/user-inactive.png",
      },
      // msg: '近期诈骗频发，学员谨防上当，点击声明～',
      msg: '点击查看详情',
      showMarquee: true
    };
  },
  mounted(){
    this.getBaseInfo()
    // this.timer = setInterval(() => {
    //   let first = this.msg.substring(0, 1);
    //   let last = this.msg.substring(1);
    //   this.msg = last + first;
    // },200)
  },
  methods: {
    handleShowMarquee(){
      this.showMarquee = false
    },
    toIndex() {
      this.$router.push("/");
    },
    getBaseInfo() {
      this.$Api.indexBaseInfo().then(({ code, data }) => {
        this.baseInfo = data;
        this.serviceTelLists = data?.service?.serviceTelLists
      });
    },
    handleLinkDing() {
      // window.open('https://www.cuour.org/page/843')
      // window.open('https://www.cuour.org/page/hezuojiameng')
      // window.open('https://mp.weixin.qq.com/s?__biz=MzA5ODc2MDgyMg==&mid=2652614200&idx=1&sn=3a4998513dc688f3a712823339b3444f&chksm=8b63af21bc142637fdced79931f1777748537019d91ec07f84c0589d4a845c7de88089917e9f&token=1205239395&lang=zh_CN#rd')
      window.open('https://mp.weixin.qq.com/s?__biz=MzA5ODc2MDgyMg==&mid=2652614412&idx=1&sn=72afd5674933a9651aa412d88f4b5e38&chksm=8b63ae15bc1427030c7aebe37dcae18b4feea494959009a6564f5fd4c87c7b48898e120b94ff&token=1722526060&lang=zh_CN#rd')

    }
  },
};
</script>

<style>
.footer-tabber {
  height: 120px !important;
}
.footer-tabber img {
  width: 50px !important;
  height: 50px !important;
}
</style>
<style lang="less" scoped>
.pd30 {
  padding: 0 30px;
  img {
    width: 68px;
    height: 68px;
    margin-right: 20px;
  }
  span {
    margin-right: 20px;
  }
  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    padding: 40px 0;
    border-bottom: 1px solid #f1f1f1;
    &:last-child {
      border-bottom: 0;
    }
  }
  button {
    width: 420px;
    height: 88px;
    background: #0dd280;
    border-radius: 44px 44px 44px 44px;
    color: #fff;
    font-size: 30px;
  }
}
.marquee{
  position: fixed;
  bottom: 220px;
  right: 0px;
  width: 163px;
  height: 181px;
  background: #ccc;
  overflow: hidden;
  background: url('../assets/images/fanzha-h5.png') no-repeat;
  background-size: 100%;
  background-position: 0% 0%;
  z-index: 999;
  p{
    position: absolute;
    bottom: 35px;
    left: 45px;
    width: 130px;
    font-size: 19px;
    font-weight: 400;
    color: #FFE1E4;
    white-space: nowrap;
  }
  marquee{
    position: absolute;
    bottom: 35px;
    left: 30px;
    // width: 130px;
    font-size: 19px;
    font-weight: 400;
    color: #FFE1E4;
    white-space: nowrap;
  }
}
.marquee-box{
  position: fixed;
  bottom: 340px;
  right: 6px;
  width: 30px;
  z-index: 10000;
}
.marquee-img{
  width: 30px;
  z-index: 10000;
}
</style>
