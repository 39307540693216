<!-- 媒体采访 / 媒体荣誉等组件 -->
<template>
  <div class="commonItem">
    <div v-if="viewShow" class="eleSwiper">
      <section>
        <h5 class="section-title">
          <span class="t">赛优简介</span>
          <img alt="" class="icon-section" src="@img/icon-index-6.png" />
        </h5>
        <brief-introduction
          :data="descListData.rotationVideoInfo"
        ></brief-introduction>
      </section>
    </div>
    <div v-if="viewShow" class="fontPosition">ABOUT</div>
    <div v-if="viewShow" class="descInfo">
      <div class="about_cuour">
        <div>{{ descripTitle }}</div>
        <p>{{ descripContent }}</p>
      </div>
    </div>
    <swiper-teaching-2
      v-if="viewShow"
      :data="descListData.fixMapInfo"
    ></swiper-teaching-2>
    <div v-if="viewShow" class="splitLine"></div>
    <section v-if="viewShow" class="padding_bottom">
      <h5 class="section-title padding_left">
        <span class="t">授课形式</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png" />
      </h5>
      <swiper-teaching-1></swiper-teaching-1>
      <swiper-teaching-2-copy></swiper-teaching-2-copy>
    </section>
    <div v-if="viewShow" class="splitLine"></div>
    <section v-if="viewShow" class="padding_bottom">
      <h5 class="section-title padding_left">
        <span class="t bold">金牌讲师</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png" />
      </h5>
      <swiper-teacher></swiper-teacher>
    </section>
    <div v-if="viewShow" class="splitLine"></div>
    <section>
      <h5 class="section-title">
        <span class="t">媒体报道</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png" />
      </h5>
      <div class="content">
        <div class="tabs">
          <div class="items style21">
            <div
              v-for="(r, i) in datas.media_report"
              :key="i"
              @click="$openLink(r.href)"
            >
              <div class="img-wrap">
                <img :src="r.src" alt="" class="img" />
              </div>
              <div class="des">{{ r && r.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-show="mediaInterviewList.length > 0">
      <h5 class="section-title">
        <span class="t">媒体采访</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png" />
      </h5>
      <div class="content">
        <div class="tabs">
          <van-swipe
            :autoplay="5000"
            class="my-swipe"
            indicator-color="white"
            @change="onChange"
          >
            <van-swipe-item
              v-for="(cur, i) in mediaInterviewList"
              :key="i"
              class="media_interview"
            >
              <video
                ref="videos"
                :poster="$utils.CDNURL + '/img/mediaImg.png'"
                autoplay
                class="cs video"
                loop
                muted="muted"
                playsinline="true"
                webkit-playsinline="true"
                @click="playVideo(cur)"
              ></video>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </section>
    <section>
      <h5 class="section-title">
        <span class="t">荣誉奖牌</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png" />
      </h5>
      <div class="content scrollbar">
        <div class="">
          <div class="flex">
            <div
              v-for="(h, i) in datas.honor_medals"
              :key="i"
              class="jiangpai"
              @click="thumbnail(h.src)"
            >
              <img :src="h.src" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h5 class="section-title margin_bottom2">
        <span class="t">全国分校</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png" />
      </h5>
      <school></school>
    </section>
    <div v-show="thumbnailImg" class="dialog" @click="closeDialog">
      <img :src="thumbnailSrc" alt="" />
    </div>
  </div>
</template>

<script>
import Tabs from "@com/Tabs.vue";
import SwiperTeaching1 from "@com/swiperTeaching1.vue";
import SwiperTeaching2 from "@com/swiperTeaching2.vue";
import SwiperTeaching2Copy from "@com/swiperTeaching2Copy.vue";
import SwiperTeacher from "@com/swiperTeacher.vue";
import BriefIntroduction from "@com/briefIntroduction.vue";
import school from "@com/school.vue";

export default {
  components: {
    Tabs,
    SwiperTeaching1,
    SwiperTeaching2,
    SwiperTeacher,
    BriefIntroduction,
    SwiperTeaching2Copy,
    school,
  },
  data() {
    return {
      campusList: [], // 全国分校
      campusTypeList: [],
      campusType: [],
      campusActiveType: null,
      datas: {},
      activePlace: "",
      thumbnailImg: false,
      thumbnailSrc: "",
      descListData: {},
      mediaInterviewList: [],
      descripTitle: "", // 描述标题
      descripContent: "", // 描述内容
      viewShow: this.$route.name == "Index" ? false : true,
    };
  },
  created() {
    // rem适配
    const setFontSize = () => {
      var html = document.documentElement;
      var windowWidth = html.clientWidth;
      if (windowWidth <= 750) {
        html.style.fontSize = windowWidth / 7.5 + "px";
      } else {
        html.style.fontSize = "100px";
      }
    };
    window.onresize = setFontSize;
    setFontSize();

    this.$Api.descList().then((res) => {
      this.descListData = res.data;
      this.descripTitle = res.data?.descInfo?.title;
      this.descripContent = res.data?.descInfo?.content;
    });
  },
  mounted() {
    // 获取媒体采访的数据
    this.getIndex();
    document.addEventListener("click", (e) => {
      if (e.target.className !== "jiangpai") {
        this.thumbnailImg = false;
      }
    });
  },
  methods: {
    onSlideChange() {},

    thumbnail(src) {
      this.thumbnailSrc = src;
      this.thumbnailImg = true;
    },
    closeDialog() {},
    onSwiper() {},
    onChange() {},
    handleTypeChange() {
      // this.campusTypeList = [];
      this.$forceUpdate();
      setTimeout(() => {
        const f = this.campusList.filter(
          (it) => it.city === this.campusActiveType
        );
        if (f.length > 0) {
          this.campusTypeList = f[0].schoolLists;
        } else {
          this.campusTypeList = [];
        }
      }, 100);
    },
    // 媒体采访荣誉
    async getIndex() {
      const $index = await this.$Api.index();
      this.datas = $index.data;
      
      // 筛选出有标题的媒体采访视频
      this.mediaInterviewList = []
      const medias = this.datas?.media_interview || []
      medias.forEach(item => {
        if(item.title) {
          this.mediaInterviewList.push(item)
        }
      })
    },
    playVideo(cur) {
      this.$bus.$emit("playMedia", cur.bigVideoSrc);
    },
    getBranchCampusList() {
      this.$Api.branchCampusList().then(({ code, data }) => {
        this.campusList = data.lists;
        this.campusList.forEach((it) => {
          this.campusType.push({
            value: it.city,
            label: it.city,
          });
        });
        this.campusActiveType = this.campusType[0].value;
        if (this.campusList.length > 0) {
          this.activePlace = this.campusList[0].city;
        }
        this.handleTypeChange();
        this.$forceUpdate();
      });
    },
  },
};
</script>
<style lang="less">
.scrollbar {
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.my-swipe1 .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
</style>
<style>
.splitLine {
  width: 100vw;
  height: 0.1rem;
  background: #f3f3f3;
  margin-bottom: 0.3rem;
}

.my-swipe .van-swipe-item {
  color: #fff;
}

.fenxiao-swipe .van-swipe__indicators {
  bottom: 50px;
}

.swiper-tabs {
  padding-bottom: 80px;
}
</style>
<style lang="less" scoped>
.commonItem {
  position: relative;
}

.descInfo {
  padding: 0 0.3rem 0.3rem 0.3rem;
  margin-top: 1.85rem;
}

.padding_left {
  padding-left: 0.3rem;
}

.padding_bottom {
  padding: 0 0 3vw 0;
}

.media_interview {
  border-radius: 1.333vw;
}

.margin_bottom2 {
  margin-bottom: 2vw;
}

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
}

.about_cuour {
  background: #f7f7f7;
  margin-top: 1.32rem;
  padding: 0.3rem;
  padding-bottom: 0.6rem;
  border-radius: 0.1rem;

  div {
    color: #323c32;
    font-size: 0.5rem;
    margin: 0.3rem 0 0.2rem 0;
    font-weight: bold;
  }

  p {
    color: #898d89;
    font-size: 0.22rem;
    line-height: 0.39rem;
    text-align: justify;
  }
}

.fontPosition {
  position: absolute;
  right: 0.3rem;
  top: 5.4rem;
  color: #f7f7f7;
  font-size: 1.4rem;
  font-weight: bold;
}

.eleSwiper section {
  padding: 0;

  h5 {
    padding-left: 4vw;
  }
}

.section-title {
  margin-bottom: 30px;
}

.style1 {
  display: inline-block;
}

.jiangpai {
  padding: 0.1rem;
  width: 35vw !important;

  border-radius: 0.1rem;
  border: 1px solid #ececec;
  margin-right: 0.15rem;

  :last-child {
    margin-right: 0;
  }

  img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }
}

// .my-swipe .van-swipe-item {
//   color: #fff;
//   font-size: 20px;
//   line-height: 0;
//   text-align: center;
//   background-color: #39a9ed;
// }

.style2 {
  & > div {
    width: 218px;
    padding: 0;
    background: #fff;
    border-radius: 10px;
  }

  .img-wrap {
    width: 219px;
    height: 142px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #ececec;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 25px;
      width: auto;
    }

    .img {
      // height: 25px;
      // width: auto;
      width: 100%;
      height: 100%;
    }

    margin-bottom: 10px;
  }

  .des {
    color: #898d89;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 24px;
    background: #fff;
  }
}

.caifang-img {
  width: 100%;
  height: auto;
}

.fenxiao-wrap {
  width: 501px;
  margin: 0 auto;

  .fenxiao {
    margin: 0 auto;
    // height: 647px;
    width: 501px;
  }

  .fenxiao-title {
    font-size: 32px;
    margin-bottom: 25px;
    width: 100%;
    margin-top: 25px;
    font-weight: bolder;
  }

  .fenxiao-img {
    width: 501px;
    height: 307px;
    border: 10px solid #d8d8d8;
    border-radius: 10px;
    margin: 30px auto;
    display: block;
  }
}

.my-swipe {
  height: auto;
}
</style>
<style>
/* .my-swipe {
  height: 300px;
} */

.fenxiao p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.fenxiao h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

/* .van-swipe-item{
  background: #000;
  border-radius: 1.333vw;
} */
.video {
  width: 690px;
  height: 388px;
  border-radius: 10px;
}

.van-swipe-item {
  border-radius: 10px !important;
}

.my-swipe van-swipe {
  border-radius: 10px;
}

.dialog {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(76, 76, 76, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog img {
  width: 99vw;
}
</style>
