<template>
  <div class="swiperTeaching2">
    <div class="swiper-container" id="swiper2">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in data"
          :key="index"
        >
          <img :src="item.url" class="bookImg" />
          <div class="bookName">
            <span>{{ item.name }}</span>
            <!-- <p>赛优教育成立于2009年中国政法大学研究生院，一开始专注研究生考试培训。</p> -->
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";
export default {
  data() {
    return {
      bookSwiper: [
        {
          img: require("../assets/images/2222.png"),
        },
        {
          img: require("../assets/images/2222.png"),
        },
        {
          img: require("../assets/images/2222.png"),
        },
        {
          img: require("../assets/images/2222.png"),
        },
        {
          img: require("../assets/images/2222.png"),
        },
        {
          img: require("../assets/images/2222.png"),
        },
      ],
    };
  },
  created() {
    // rem适配
    const setFontSize = () => {
      var html = document.documentElement;
      var windowWidth = html.clientWidth;
      if (windowWidth <= 750) {
        html.style.fontSize = windowWidth / 7.5 + "px";
      } else {
        html.style.fontSize = "100px";
      }
    };
    window.onresize = setFontSize;
    setFontSize();
  },
  props:['data'],
  mounted() {
    console.log(this.data)
    setTimeout(() => {
      new Swiper("#swiper2", {
        direction: "horizontal",
        speed: 600,
        loop: true,
        autoplay: false,
        observer: true,
        observeParents: true,
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        slideToClickedSlide: true,
        coverflowEffect: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: -8, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 30, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 5,
          slideShadows: false, //开启slide阴影。默认 true。
        },
      });
    }, 100);
  },
};
</script>

<style lang="less" scoped>
.swiperTeaching2{
  #swiper2 {
    width: 100%;
    margin-top: .3rem;
    margin-bottom: .5rem;
  }
  .swiper-slide {
    width: 4rem !important;
    // height: 3.06rem;
    // font-size: 14px;
    // text-align: center;
    border-radius: .1rem .1rem 0 0;
    position: relative;
  }
  .bookImg {
    width: 100% !important;
    height: 2.22rem;
    border-radius: .1rem .1rem 0 0;
  }
  .bookName {
    width: 100%;
    // display: none;
    // margin-top: -55px;
    padding: .35rem .2rem .5rem .2rem;
    background: #f7f7f7;
    color: #323C32;
    font-size: .32rem;
    border-radius: 0 0 .1rem .1rem;
    margin-top: -.08rem;
    span{
      margin-bottom: .2rem;
    }
    p{
      font-size: .22rem;
      color: #898D89;
      text-align: justify;
    }
  }
  .swiper-slide-active .bookName {
    display: block;
  }
}
</style>
