import { Service } from "./service";
import Mock from "./mock";
let COLLECT_URL = process.env['VUE_APP_COLLECT_URL']

const mixinDatas = async (data) => {
  // if (process.env.NODE_ENV === "development" && false) {
  //   console.log(Mock[data.url]);
  //   return new Promise((resolve, reject) => {
  //     resolve(Mock[data.url]);
  //   });
  // }
  return Service({ ...data });
};

export function index() {
  return mixinDatas({
    url: "/api_pc/index/index",
  });
}

// 头部尾部信息
export function indexBaseInfo() {
  return mixinDatas({
    url: "/api_pc/index/base-info",
    method: "get",
  });
}

export function collegeCategories() {
  return mixinDatas({
    url: "/api_pc/index/college/categories",
    method: "get",
  });
}

export function collegeDetail(params) {
  return mixinDatas({
    url: "/api_pc/index/college/detail",
    method: "get",
    params,
  });
}

// 课程商城分类
export function shoppingCategory() {
  return mixinDatas({
    url: "/api_pc/index/shopping-category",
    method: "get",
  });
}

/**
 type 全国分校
 */
export function branchCampusList(data) {
  return mixinDatas({
    url: "/api_pc/index/branch-campus-lists",
    method: "get",
  });
}

/**
 * 课程商城列表
 * @param {string} code 分类编号
 * @returns
 */
export function shoppingMallList(code) {
  return mixinDatas({
    url: "/api_pc/index/shopping-mall-lists",
    method: "get",
    params: {
      code,
    },
  });
}

/**
 * @param  {data} projectId
 * @param  {data} categoryId
 */
export function classroommallIndex(data) {
  return mixinDatas({
    url: "/api_pc/classroommall/index",
    method: "get",
    params: data,
  });
}

/**
 * @param  {data} projectId
 */
export function classroommallCategory(data) {
  return mixinDatas({
    url: "/api_pc/classroommall/category-lists",
    method: "get",
    params: data,
  });
}

/**
 type 属性类型.1:院校区域,2:学费,3:学制,4:提前面试,5:院校特性
 data.1
 */
export function collegeListConfig(data) {
  return mixinDatas({
    url: "/api_pc/index/college/list/configs",
    method: "get",
  });
}

export function collegeList(data) {
  return mixinDatas({
    url: "/api_pc/index/college/list",
    method: "get",
    params: data,
  });
}

// 院校热门文章
export function collegeArticles(data) {
  return mixinDatas({
    url: "/api_pc/index/college/hot/articles",
    method: "get",
  });
}

// 院校热门图片
export function collegeHotImages(data) {
  return mixinDatas({
    url: "/api_pc/index/college/hot/images",
    method: "get",
  });
}

/**
 type 项目接口
 data.1
 */
export function indexColumnList(data) {
  return mixinDatas({
    url: "/api_pc/index/column/list",
    method: "get",
  });
}

// ========== 项目详情 ========== //
export function columnDetail(data) {
  return mixinDatas({
    url: "/api_pc/index/column/detail",
    method: "get",
    params: data,
  });
}

export function columnOpenCourseList(data) {
  return mixinDatas({
    url: "/api_pc/index/column/open_course/list",
    method: "get",
    params: data,
  });
}

export function useInfo(data) {
  return mixinDatas({
    url: "/api_pc/user/get-user-info",
    method: "get",
    params: data,
  });
}

export function companyContent(data) {
  return mixinDatas({
    url: "/api_pc/company/content",
    method: "get",
    params: data,
  });
}

// ========赛优简介========== //

export function goldTeacherList(data) {
  // 金牌讲师
  return mixinDatas({
    url: "/api_pc/gold-teacher/list",
    method: "get",
    params: data,
  });
}
export function collectViewingTime(data) {
  return mixinDatas({
    url: `/${COLLECT_URL}/mba/collectViewingTime`,
    method: "get",
    params: data,
  });
}

export function teachingList(data) {
  // 授课形式
  return mixinDatas({
    url: "/api_pc/teaching/list",
    method: "get",
    params: data,
  });
}

export function descList(data) {
  // 简介列表
  return mixinDatas({
    url: "/api_pc/desc/list",
    method: "get",
    params: data,
  });
}
