import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false
  },
  mutations: {
    set_login (state, data) {
      state.isLogin = data
    }
  },
  actions: {
    SET_LOGIN ({ commit }, data) {
      commit('set_login', data)
    }
  },
  modules: {}
})
