<template>
  <div class="selectbtn">
    <span @click="handleChange(null)" :class="{act:value == null || value == ''}">全部</span>
    <span @click="handleChange(d[valueName])" :class="{act:value === d[valueName]}" v-for="d in datas" :key="d[valueName]">{{d.text}}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    datas: {
      default: [],
    },
    valueName:{
      default:'id'
    }
  },
  methods: {
    handleChange(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang='less'>
.selectbtn {
  display: flex;
  flex-wrap: wrap;
  & > span {
    padding: 9px 20px;
    font-size: 24px;
    font-weight: 400;
    color: #898d89;
    background: #f3f3f3;
    border-radius: 10px;
    margin: 0 30px 30px 0;
    &.act {
      background: @primary-color;
      color: #fff;
    }
  }
}
</style>