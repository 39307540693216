<!-- 首页 -->
<template>
  <main>
    <div class="banner-wrap">
      <div class="banner">
        <div v-show="bannerVideoShow" class="content">
          <video
              v-for="(item, index) in bannerLists"
              v-show="index === playIndex"
              :key="index"
              :ref="'videos' + index"
              autoplay
              class="cs video"
              muted
              playsinline="true"
              preload="none"
              webkit-playsinline="true"
              @click="goCollegeDetail(item)"
              @ended="onVideoEnd"
              @error="onVideoEnd"
          ></video>
        </div>
        <div v-show="!bannerVideoShow" class="hideVideo"></div>
        <div class="thumbnail">
          <ul>
            <template v-for="(d, i) in bannerLists">
              <li v-if="i < 4" :key="i" @click="goCollegeDetail(d)">
                <img :src="d.tagPic" alt=""/>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div class="search bgff">
      <div v-if="datas.collegeLibrary" class="selects flex-align">
        <Select
            v-model="params.categoryId"
            :datas="datas.collegeLibrary['collegeLists']"
            class="s1"
        ></Select>
        <Select
            v-model="params.areaName"
            :datas="datas.collegeLibrary['areaLists']"
            class="s2"
        ></Select>
        <button class="search-btn1" @click="search">
          <span>查找</span>
          <img alt="" src="@img/icon-search-1.png"/>
        </button>
      </div>
      <div class="inputs">
        <input
            v-model="params.collegeName"
            placeholder="请输入想要查找的院校名称"
            type="text"
        />
        <button @click="search">立即查询</button>
      </div>
    </div>
    <div v-for="(column, index) in centerColumn" :key="index">
      <section>
        <h5 class="section-title" @click="goProject(column)">
          <span class="t">{{ column.info[0].value }}</span>
          <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
        </h5>
        <div class="content">
          <div>
            <div @click="playCourseVideo(column)">
              <img :src="column.leftPart[0].wappic" alt="" class="center-img"/>
            </div>
            <div class="tabs special">
              <Tabs
                  v-model="centerColumnTab[index]"
                  :datas="centerColumnClasses[index]"
                  @changeIndex="
                  (ind) => {
                    ctIndex[index] = ind;
                  }
                "
              ></Tabs>
              <div class="items style1">
                <!-- {{centerColumnClasses[index]}} -->
                <div
                    v-for="(c, i) in column[centerColumnTab[index]]"
                    :key="i"
                    @click="goProject(column, ctIndex[index] + 1, c.code)"
                >
                  <div v-if="centerColumnTab[index] === typeValue" class="ksxx">
                    <div class="ksxx-body">
                      <div class="title overflow1">{{ c.title }}</div>
                      <div class="ksxx-content overflow2" v-html="c.body"></div>
                    </div>
                    <div class="ks-des">
                      {{ c.tags.length > 0 ? c.tags[0].name : "" }}
                    </div>
                  </div>
                  <div v-else>
                    <img v-if="c.src" :src="c.src" alt=""/>
                    <div class="des">{{ c.name || c.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section>
      <h5
          class="section-title"
          @click="$router.push('/course-detail?code=' + course.activeType)"
      >
        <span class="t">课程商城</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
      </h5>
      <div class="content">
        <div class="tabs">
          <Tabs
              v-model="course.activeType"
              :datas="course.type"
              labelName="name"
              valueName="code"
              @change="getCourseList"
          ></Tabs>
          <div class="items style1">
            <div
                v-for="(c, i) in course.list"
                :key="i"
                @click="$openLink(c.href)"
            >
              <img :src="c.pic" alt=""/>
              <div class="des">{{ c.title }}</div>
            </div>
            <div v-if="course.list.length === 0" style="font-size: 12px">
              暂无课程
            </div>
          </div>
        </div>
      </div>
    </section>
    <van-overlay :show="courseVideoShow" @click="stopCourseVideo">
      <div class="wrapper" @click.stop>
        <!-- <video ref="overlayVideo" :src="courseVideoUrl" controls></video> -->
        <video
            v-for="(item, index) in courseVideoList"
            v-show="item === courseVideoUrl"
            :key="index"
            :ref="'courseVideo' + index"
            controls
        ></video>
      </div>
    </van-overlay>
    <CommonItem></CommonItem>
    <Tabbar></Tabbar>
  </main>
</template>

<script>
import Select from "@com/Select.vue";
import Tabs from "@com/Tabs.vue";
import CommonItem from "@com/common-item.vue";
import Tabbar from "@com/Tabbar.vue";
// hls.js - 用于 video 播放hls格式的视频
import Hls from "hls.js";
// 检测视频地址是否过期
// import {checkHlsUrl} from "@/core/utils.js";

export default {
  components: {
    Select,
    Tabs,
    CommonItem,
    Tabbar,
  },
  data() {
    return {
      courseVideoShow: false, // 课程项目视频播放 - 弹框显隐
      courseVideoUrl: "", // 课程项目列表 - 需要播放的视频url
      courseVideoList: [], // 课程列表 - 弹框视频 - url的数组
      datas: {},
      bannerLists: [], // banner视频列表
      // 首页搜索条件
      params: {
        categoryId: "", // 院校库
        areaName: "", // 地区
        collegeName: "", // 关键字
      },
      course: {
        list: [], //课程商城列表
        type: [], // 课程商城分类
        activeType: "", // 默认激活的分类
      },
      ctIndex: [0, 0, 0, 0],
      // collegeConfig: {}, // 院校搜索配置
      centerColumn: null,
      centerColumnClasses: [],
      // centerColumnTab: null,
      centerColumnTab: [],
      // 院校库
      // categories: [],
      typeValue: null,
      bannerVideoShow: true,
      isMouseMoveVideo: false,
      bannerVideoUrl: "",
      playIndex: 0, // banner当前播放视频的index
      indicator: false,
      bannerLimitNumber: 0, // 阻止循环请求
    };
  },
  computed: {
    centerColumnList() {
      return [];
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.$bus.$on("hideVideo", (val) => {
        this.bannerVideoShow = !val;
      });
    });
    // 初始化首页数据
    this.getIndex();
    this.getCenterColumn();
    this.getCourse();
    // 获取院校搜索配置
    this.getCollegeListConfig();
    // this.getCollegeCategories();
  },
  beforeDestroy() {
    // 销毁hls对象
    this.bannerLists.forEach((item, index) => {
      if (this[`hls${index}`]) {
        this.$refs[`videos${index}`][0] &&
        this.$refs[`videos${index}`][0].pause();
        this[`hls${index}`].destroy();
        this[`hls${index}`] = null;
      }
    });
    // 销毁 courseHls 对象
    this.courseVideoList.forEach((item, index) => {
      if (this[`courseHls${index}`]) {
        this.$refs[`courseVideo${index}`][0] &&
        this.$refs[`courseVideo${index}`][0].pause();
        this[`courseHls${index}`].destroy();
        this[`courseHls${index}`] = null;
      }
    });
  },
  methods: {
    getIndex() {
      this.$Api.index().then(({code, data}) => {
        // data.
        data.bannerLists = data.bannerLists.filter((d) => d.collegeId != "");
        data.collegeLibrary["areaLists"].forEach((d) => {
          d.value = d.name;
          d.label = d.name;
        });
        data.collegeLibrary["collegeLists"].forEach((d) => {
          d.value = d.id;
          d.label = d.name;
        });
        this.datas = data;
        // banner视频列表
        this.bannerLists = data.bannerLists;

        if (data.collegeLibrary["areaLists"].length > 0) {
          this.params["areaName"] = data.collegeLibrary["areaLists"][0].label;
        }
        if (data.collegeLibrary["collegeLists"].length > 0) {
          this.params["categoryId"] = data.collegeLibrary["collegeLists"][0].id;
        }
        // 请求完成后，播放视频
        this.$nextTick(() => {
          this.playByIndex(0);
        });
      });
    },
    // getCollegeCategories() {
    //   this.$Api.collegeCategories().then(({ code, data }) => {
    //     data.forEach((d) => {
    //       d.value = d.id;
    //       d.label = d.name;
    //     });
    //     this.categories = data;
    //     this.params["categoryId"] = data[0].value;
    //   });
    // },
    getCenterColumn() {
      this.$Api.indexColumnList().then((res) => {
        const data = res.data.column;
        this.centerColumn = data;
        data.forEach((d, i) => {
          const part0Value = d.centerPartTitle1[0].value;
          const part1Value = d.centerPartTitle2[0].value;
          const part2Value = d.centerPartTitle3[0].value;
          const pushDatas = [
            {
              value: part0Value,
              label: part0Value,
              list: d.centerPartContent1,
            },
            {
              value: part1Value,
              label: part1Value,
              list: d.centerPartContent2,
            },
            {
              value: part2Value,
              label: part2Value,
              list: d.centerPartContent3,
            },
            {
              value: d.examInfo.value,
              label: d.examInfo.value,
              list: d.examInfo.list,
            },
          ];
          this.typeValue = d.examInfo.value;
          this.centerColumnClasses.push(pushDatas);
          d[part0Value] = d.centerPartContent1;
          d[part1Value] = d.centerPartContent2;
          d[part2Value] = d.centerPartContent3;
          d[d.examInfo.value] = d.examInfo.list;
          this.centerColumnTab.push(pushDatas[0].value);
        });
      });
    },
    getCourse() {
      this.$Api.shoppingCategory().then(({code, data}) => {
        this.course.type = data.categoryLists || [];
        if (data.categoryLists.length > 0) {
          this.course.activeType = data.categoryLists[0].code;
        }
        this.getCourseList(data.categoryLists[0].code);
      });
    },
    getCourseList(code) {
      this.$Api.shoppingMallList(code).then(({code, data}) => {
        this.course.list = data.lists || [];
      });
    },
    getCollegeListConfig() {
      // this.$Api.collegeListConfig().then(({ code, data }) => {
      //   for (const key in data) {
      //     this.$utils.getDropDownValue(data[key]);
      //   }
      //   console.log(data);
      //   this.collegeConfig = data;
      // });
    },
    search() {
      this.$router.push({
        path: "/search-detail",
        query: this.params,
      });
    },
    // 前往项目详情页面
    goProject(item, index, code) {
      this.$router.push({
        path:
            "/project-detail/" + item.base.code + `?index=${index}&code=${code}`,
      });
    },
    swiperSwich() {
      this.isMouseMoveVideo = false;
    },
    onVideoError() {
    },
    onVideoEnd() {
      if (this.bannerLists) {
        var endInt = this.bannerLists.length >= 4 ? 4 : this.bannerLists.length;
        if (!this.isMouseMoveVideo) {
          this.playIndex++;
          if (this.playIndex >= endInt) this.playIndex = 0;
        }
        this.playByIndex(this.playIndex);
      }
    },
    playByIndex(index) {
      try {
        // 检测视频地址是否失效
        let bannerVideoUrl = this.bannerLists[index].video;
        if (bannerVideoUrl) {
          //  初始化 hls 绑定 video。如果没有 相应的hls的实例，则创建；否则播放hls实例
          if (!this[`hls${index}`]) {
            this.$nextTick(() => {
              this.getHlsStream(index);
            });
          } else {
            const refElement = this.$refs[`videos${index}`][0];
            refElement && refElement.play();
          }
        } else {
          console.log("媒体采访视频地址不存在");
        }
      } catch (error) {
        console.log("首页获取视频失败：", error);
      }
    },
    // 首页banner - hls 绑定 video 标签
    getHlsStream(index) {
      const refElement = this.$refs[`videos${index}`][0];
      let currentVideoUrl = this.bannerLists[index].video;
      if (Hls.isSupported()) {
        if (!this[`hls${index}`]) {
          this[`hls${index}`] = new Hls();
        }
        this[`hls${index}`].loadSource(currentVideoUrl);
        this[`hls${index}`].attachMedia(refElement);
        this[`hls${index}`].on(Hls.Events.MANIFEST_PARSED, () => {
          // console.log("加载成功------index");
          refElement && refElement.play();
        });
        this[`hls${index}`].on(Hls.Events.ERROR, (event, data) => {
          // console.log("加载失败 -------- index");
        });
      } else if (refElement.canPlayType("application/vnd.apple.mpegurl")) {
        refElement.src = currentVideoUrl;
        refElement.addEventListener("loadedmetadata", function () {
          refElement && refElement.play();
        });
      } else {
        // 火狐兼容性提示
        const explorer = window.navigator.userAgent;
        if (explorer.indexOf("Firefox") >= 0) {
          this.$message.warning(
              '火狐浏览器不支持当前视频格式，请安装"Native HLS"插件！'
          );
        }
      }
    },
    // 课程项目列表 - 视频播放
    playCourseVideo(item) {
      // 当前播放的课程项目视频地址
      this.courseVideoUrl = item.leftPart[0].video.videoUrl;
      let hasVideoUrl =
          this.courseVideoList.indexOf(this.courseVideoUrl) !== -1;
      // 地址存在，且不在数组中
      if (this.courseVideoUrl && !hasVideoUrl) {
        // 显示弹框
        this.courseVideoShow = true;
        this.courseVideoList.push(this.courseVideoUrl);
        // 格式化video元素，使其绑定hls
        let currentIndex = this.courseVideoList.indexOf(this.courseVideoUrl)
        this.$nextTick(() => {
          this.getCourseHlsStream(currentIndex);
        });
      } else if (this.courseVideoUrl && hasVideoUrl) {
        // 显示弹框
        this.courseVideoShow = true;
        // 已存在则播放视频
        this.$refs[`courseVideo${index}`][0] &&
        this.$refs[`courseVideo${index}`][0].play();
      } else if (!this.courseVideoUrl) {
        this.$message({
          type: "error",
          message: "视频地址不存在",
        });
        console.log("视频地址不存在");
      }
    },
    // 课程项目列表 - 视频停止
    stopCourseVideo() {
      // 暂停视频
      // this.$refs.overlayVideo.pause()
      this.courseVideoList?.forEach((item, index) => {
        this.$refs[`courseVideo${index}`][0] &&
        this.$refs[`courseVideo${index}`][0].pause();
      });
      // 关闭弹框
      this.courseVideoShow = false;
    },
    // 首页- 课程项目列表 - hls 绑定 video 标签
    getCourseHlsStream(index) {
      try {
        const refElement = this.$refs[`courseVideo${index}`][0];
        let currentVideoUrl = this.courseVideoList[index];
        if (Hls.isSupported()) {
          if (!this[`courseHls${index}`]) {
            this[`courseHls${index}`] = new Hls();
          }
          this[`courseHls${index}`].loadSource(currentVideoUrl);
          this[`courseHls${index}`].attachMedia(refElement);
          this[`courseHls${index}`].on(Hls.Events.MANIFEST_PARSED, () => {
            // console.log("加载成功------index");
            refElement && refElement.play();
          });
          this[`courseHls${index}`].on(Hls.Events.ERROR, (event, data) => {
            // console.log("加载失败 -------- index");
          });
        } else if (refElement.canPlayType("application/vnd.apple.mpegurl")) {
          refElement.src = currentVideoUrl;
          refElement.addEventListener("loadedmetadata", function () {
            refElement && refElement.play();
          });
        } else {
          // 火狐兼容性提示
          const explorer = window.navigator.userAgent;
          if (explorer.indexOf("Firefox") >= 0) {
            this.$message.warning(
                '火狐浏览器不支持当前视频格式，请安装"Native HLS"插件！'
            );
          }
        }
      } catch (error) {
        console.log("getCourseHlsStream 错误：", error);
      }
    },
    swiperTo(college) {
      this.$router.push({
        path: `/school-detail/${college.collegeId}`,
      });
    },
    goCollegeDetail(college) {
      this.$router.push({
        path: `/school-detail/${college.collegeId}`,
      });
    },
  },
  destroyed() {
    clearInterval(this.videoTimer);
  },
};
</script>

<style lang="less" scoped>
.center-img {
  width: 690px;
  height: 197px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    //width: 400px;
    width: 80%;
  }
}

.hideVideo {
  width: 690px;
  height: 308px;
}

.banner-wrap {
  padding: 20px 30px;
  background: #fff;

  .banner {
    position: relative;
    width: 690px;
    height: 388px;
    // background: #000;
    border-radius: 10px;
    overflow: hidden;

    .item-img {
      width: 690px;
      height: 388px;
    }

    .video {
      width: 690px;
      height: 388px;
      border-radius: 10px;
    }

    .thumbnail {
      position: absolute;
      bottom: 20px;
      width: 100%;

      img {
        width: 150px;
        height: 75px;
        border-radius: 10px;
      }

      ul {
        display: flex;
        justify-content: center;
        padding: 0 23px;
        width: 100%;
      }

      li {
        width: 150px;
        height: 75px;
        margin-right: 15px;
      }
    }
  }
}

.search {
  padding: 0 30px;

  .selects {
    justify-content: space-between;
  }

  .s1 {
    width: 348px;
  }

  .s2 {
    width: 164px;
  }

  .search-btn1 {
    width: 138px;
    height: 63px;
    background: @primary-color;
    border-radius: 10px;
    color: #fff;

    font-size: 26px;

    img {
      width: 28px;
      height: 28px;
      margin-left: 10px;
    }
  }

  .inputs {
    width: 690px;
    border-radius: 10px;
    position: relative;
    margin-top: 20px;

    input {
      width: 690px;
      height: 63px;
      border-radius: 10px;
      padding: 0 2.4vw 0 4vw;
      border: 0;
      outline: 0;
      // letter-spacing:10px;
      border: 1px solid #cecece;

      &::placeholder {
        font-size: 25px;
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 26px;
      height: 63px;
      width: 190px;
      border-radius: 0 10px 10px 0;
      background: @primary-color;
      color: #fff;
    }
  }
}

.ksxx {
  margin-right: 20px;

  .ksxx-body {
    width: 219px;
    height: 142px;
    background: #f2f2f2;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 20px;

    .title {
      font-size: 20px;
      color: #323c32;
      margin-bottom: 13px;
      font-weight: bolder;
    }

    .ksxx-content {
      color: #aaadaa;
      font-size: 18px;
    }
  }

  .ks-des {
    text-align: center;
    font-size: 24px;
    color: #898d89;
  }
}

.tabs .style2 {
  width: 100% !important;
  overflow: auto;
}
</style>
