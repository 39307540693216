import { request } from './request'

/**
 * 根据classId 获取班级信息
 * @params { number } classroomId 班级ID
 * 
*/
export const getInfoByClassId = (classroomId) => {
    return request.post('/api/quick_paycenter/get_backend_infos', {
        classroomId
    })
}

/**
 * 获取花呗分期信息
 * @params { number } totalPrice 实付价格
 * 
*/
export const getPaymentTimes = (totalPrice) => {
    return request.post('/api/quick_paycenter/get_backend_infos', {
        totalPrice
    })
}

/**
 * 跳转支付宝支付
 * 
*/
export const getAlipayParams = data => {
    return request.post('/api/quick_paycenter/create_order', data)
}

/**
 * 跳转花呗分期三期免息
 * 
*/
export const getHBpayParams = data => {
    return request.post('/api/quick_paycenter/create_listen_order', data)
}