<template>
  <div class="sywap-tabs" :class='{has_line:active}'>
    <ul>
      <li :class="{act:d[valueName] === value, bottom_line: d[valueName] === value && active}" v-for="(d,i) in datas" :key="i" @click="handleChange(d,i)">
        <!-- <a v-if="href">{{d[labelName]}}</a> -->
        <span>{{d[labelName]}}</span>
      </li>

      <li v-if="datas.length === 0">暂无数据</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    datas: {
      default: [],
    },
    value: {},
    active: {
      type: Boolean,
      default: false,
    },
    labelName: {
      default: "label",
    },
    valueName: {
      default: "value",
    },
    href:{
      default:false
    },
    index:{

    }
  },
  data() {
    return {};
  },
  mounted(){
    // document.body.scrollTop = "100px"
  },
  methods: {
    handleChange(val,index) {
      this.$emit("input", val[this.valueName]);
      this.$emit("change", val[this.valueName]);
      this.$emit('changeIndex',index)
      
      if(this.href){
        window.scrollTo(0,document.querySelector(val.href).offsetTop - 70)
      }
    },
  },
};
</script>

<style lang="less" scoped>
.sywap-tabs {
  margin-bottom: 30px;
  margin-top: 10px;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  ul {
    width: 100%;
    display: flex;
    // width: 1000px;
    white-space: nowrap;
    align-items: center;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  li {
    color: #323c32;
    border-left: 1px solid #dcdee5;
    // margin-right: 31px;
    // margin-left: 31px;
    font-size: 24px;
    position: relative;
    padding: 0 30px;
    &:last-child {
      margin-right: 0;
      // border-right: 0;
    }
    &:first-child {
      padding-left: 0;
      border-left: 0;
    }
    &.act {
      font-size: 30px;
      font-weight: bold;
    }
    &.bottom_line {
      &::after {
        position: absolute;
        bottom: -18px;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        background: @primary-color;
        height: 6px;
        border-radius: 6px;
      }
    }
  }

  &.has_line {
    padding-bottom: 18px;
  }
}
.special li{
  width: 25%;
  text-align: center;
  // margin-left: 14px;
  margin-right: 14px;
  &:first-child {
    padding-left: 0;
    margin-left: 0;
    // margin-right: 0px;
    // padding-right: 0;

  }
}
.has_line{
  margin-bottom: 30px;
  margin-top: 10px;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  ul {
    width: 100%;
    display: flex;
    // width: 1000px;
    white-space: nowrap;
    align-items: center;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  li {
    color: #323c32;
    border-left: 1px solid #dcdee5;
    // margin-right: 31px;
    // margin-left: 31px;
    font-size: 24px;
    position: relative;
    padding: 0 30px;
    &:last-child {
      margin-right: 0;
      // border-right: 0;
    }
    &:first-child {
      // padding-left: 0;
      border-left: 0;
    }
    &.act {
      font-size: 30px;
      font-weight: bold;
    }
    &.bottom_line {
      &::after {
        position: absolute;
        bottom: -18px;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        background: @primary-color;
        height: 6px;
        border-radius: 6px;
      }
    }
  }

  &.has_line {
    padding-bottom: 18px;
  }
}
</style>