<!-- 赛优简介 - 页面 -->
<template>
  <div class="briefIntroduction">
    <van-overlay :show="isIntroModalShow" lock-scroll @click="stopIntroVideo">
      <video
          v-for="(item, index) in introVideoList"
          v-show="currentIntroVideoUrl === item"
          :key="index"
          :ref="'introVideo' + index"
          class="intro-video"
          controls
          playsinline="true"
          webkit-playsinline
      ></video>
    </van-overlay>
    <div id="swiper1" class="swiper-container">
      <div class="swiper-wrapper">
        <div
            v-for="(item, index) in data"
            :key="index"
            class="swiper-slide"
            @click="handlePlayIntroVideo(item.video_url)"
        >
          <img :src="item.url" class="bookImg"/>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";
// hls.js - 用于 video 播放hls格式的视频
import Hls from "hls.js";

export default {
  data() {
    return {
      isIntroModalShow: false, // 视频弹框的显隐
      currentIntroVideoUrl: "", // 当前的视频地址
      introVideoList: [], // 赛优简介视频地址列表
    };
  },
  props: ["data"],
  created() {
    // rem适配
    const setFontSize = () => {
      var html = document.documentElement;
      var windowWidth = html.clientWidth;
      if (windowWidth <= 750) {
        html.style.fontSize = windowWidth / 7.5 + "px";
      } else {
        html.style.fontSize = "100px";
      }
    };
    window.onresize = setFontSize;
    setFontSize();
  },
  mounted() {
    setTimeout(() => {
      new Swiper("#swiper1", {
        direction: "horizontal",
        speed: 500,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
        },
        preventLinksPropagation: false,
        observer: true,
        observeParents: true,
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: -10, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 20, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 3,
          slideShadows: false, //开启slide阴影。默认 true。
        },
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          slideChangeTransitionEnd: function () {
            this.autoplay.start(); //就加这个玩意儿
          },
        },
      });
    }, 100);
  },
  beforeDestroy() {
    // 销毁hls对象
    this.introVideoList.forEach((item, index) => {
      if (this[`introHls${index}`]) {
        this.$refs[`introVideo${index}`][0] &&
        this.$refs[`introVideo${index}`][0].pause();
        this[`introHls${index}`].destroy();
        this[`introHls${index}`] = null;
      }
    });
  },
  methods: {
    handlePlayIntroVideo(url) {
      // 显示弹框
      this.isIntroModalShow = true;
      // 当前的url地址
      this.currentIntroVideoUrl = url;
      // url是否存在于数组中
      let hasVideoUrl =
          this.introVideoList.indexOf(this.currentIntroVideoUrl) !== -1;
      if (this.currentIntroVideoUrl && !hasVideoUrl) {
        // 不存在则加入到数组中
        this.introVideoList.push(this.currentIntroVideoUrl);
        // 格式化video元素，使其绑定hls
        let index = this.introVideoList.indexOf(this.currentIntroVideoUrl); // 当前地址在数组中的index
        this.$nextTick(() => {
          this.getIntroHlsStream(index);
        });
      } else if (this.currentIntroVideoUrl && hasVideoUrl) {
        // 显示弹框
        this.isIntroModalShow = true;
        let index = this.introVideoList.indexOf(this.currentIntroVideoUrl); // 当前地址在数组中的index
        // 存在则播放视频
        this.$refs[`introVideo${index}`][0] &&
        this.$refs[`introVideo${index}`][0].play();
      } else if (!this.currentIntroVideoUrl) {
        // 地址不存在则报错；地址存在且已存在于数组中，会直接播放
        this.$message({
          type: "error",
          message: "视频地址不存在",
        });
        console.log("视频地址不存在");
      }
    },
    stopIntroVideo() {
      // 关闭弹框
      this.isIntroModalShow = false;
      this.introVideoList.forEach((item, index) => {
        this.$refs[`introVideo${index}`][0] &&
        this.$refs[`introVideo${index}`][0].pause();
      });
      this.currentIntroVideoUrl = "";
    },
    // 赛优简介 - hls 绑定 video 标签
    getIntroHlsStream(index) {
      const refElement = this.$refs[`introVideo${index}`][0];
      let currentVideoUrl = this.introVideoList[index];
      if (Hls.isSupported()) {
        if (!this[`introHls${index}`]) {
          this[`introHls${index}`] = new Hls();
        }
        this[`introHls${index}`].loadSource(currentVideoUrl);
        this[`introHls${index}`].attachMedia(refElement);
        this[`introHls${index}`].on(Hls.Events.MANIFEST_PARSED, () => {
          // console.log("加载成功------index");
          refElement && refElement.play();
        });
        this[`introHls${index}`].on(Hls.Events.ERROR, (event, data) => {
          // console.log("加载失败 -------- index");
        });
      } else if (refElement.canPlayType("application/vnd.apple.mpegurl")) {
        refElement.src = currentVideoUrl;
        refElement.addEventListener("loadedmetadata", function () {
          refElement && refElement.play();
        });
      } else {
        // 火狐兼容性提示
        const explorer = window.navigator.userAgent;
        if (explorer.indexOf("Firefox") >= 0) {
          this.$message.warning(
              '火狐浏览器不支持当前视频格式，请安装"Native HLS"插件！'
          );
        }
      }
    },
  },
};
</script>

<style lang="less">
.briefIntroduction {
  #video {
    max-width: 80vw;
    max-height: 30vh;
    margin-top: -30vh;
  }

  .van-overlay {
    z-index: 999;
  }

  #swiper1 {
    width: 100%;
    /* height: 340px; */
    /* margin-top: 36px; */
  }

  .swiper-slide {
    width: 6.3rem !important;
    height: 3.54rem;
    font-size: 14px;
    text-align: center;
    line-height: 80px;
    border-radius: 8px;
    position: relative;
  }

  .swiper-pagination {
    width: 100%;
    margin-top: 0.3rem;
    --swiper-pagination-color: #0dd280; /* 两种都可以 */

    .swiper-pagination-bullet {
      margin-right: 0.1rem;
      width: 0.13rem;
      height: 0.13rem;
    }

    .swiper-pagination-bullet-active {
      background: #0dd280;
    }
  }

  .bookImg {
    width: 100% !important;
    height: 100%;
    border-radius: 0.1rem;
  }

  .bookName {
    font-size: 14px;
    width: 100%;
    display: none;
    margin-top: -55px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .swiper-slide-active .bookName {
    display: block;
  }
}

.intro-video {
  width: 80%;
}
</style>
