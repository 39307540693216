<template>
  <header id="sywap-header" class="van-hander">
    <div class="left">
      <div @click="showMenu">
        <img class="icon-1" src="@img/icon-index-1.png" alt="" />
      </div>
      <img
        class="icon-3"
        :src="$utils.CDNURL + '/img/icon-index-3.png'"
        alt=""
      />
      <div class="line"></div>
      <img class="icon-2" src="@img/icon-index-2.png" alt="" />
      <div>
        <p style="font-size: 13px">赛优课堂</p>
        <p
          style="font-size: 13px; line-height: 15px"
          @click="$openLink(baseInfo.site.appdownload)"
        >
          下载APP
        </p>
      </div>
    </div>
    <div v-show="isEnvNoticeShow" class="env-notice">
      <i class="el-icon-warning-outline" />
      {{ `提示：您正处于${envNotice}环境中` }}
    </div>
    <div class="right">
      <div class="infos" v-if="isLogin && userInfo.id">
        <div @click="$openLink(menuLists.length > 0 && menuLists[0].href)">
          <span
            v-if="(menuLists.length > 0 && menuLists[0].role) === 'ROLE_USER'"
            >学习中心</span
          >
          <span
            v-if="
              (menuLists.length > 0 && menuLists[0].role) === 'ROLE_TEACHER'
            "
            >教学中心</span
          >
        </div>
        <div class="flex-align" @click.stop="userMenu = !userMenu">
          <img
            class="icon-4"
            src="@img/icon-index-4.png"
            style="border: 1px solid rgb(220, 220, 220); border-radius: 50%"
            v-show="!userInfo.smallAvatar"
            alt=""
          />
          <img
            class="icon-4"
            :src="userInfo.smallAvatar"
            style="border: 1px solid rgb(220, 220, 220); border-radius: 50%"
            v-show="userInfo.smallAvatar"
            alt=""
          />
          <img
            class="icon-5"
            style="width: 10px; height: 10px"
            :class="userMenu ? '' : 'act'"
            src="@img/icon-index-5.png"
            alt=""
          />
        </div>
        <div class="user-menu" v-if="userMenu">
          <div v-for="(m, i) in menuLists" :key="i" @click="$openLink(m.href)">
            {{ m.title }}
          </div>
          <!-- <div>我的订单</div>
          <div>退出登录</div> -->
        </div>
      </div>
      <div class="actions" v-else>
        <div class="login-btn" @click="$openLink(baseInfo.loginUrl)">登录</div>
        <div class="line"></div>
        <div class="reg-btn" @click="$openLink(baseInfo.registerUrl)">注册</div>
      </div>
    </div>
    <van-popup v-model="navMenu" position="left">
      <div class="nav-menu">
        <h4>导航分类</h4>
        <ul>
          <li @click="toPath('/')" :class="{ act: activeId === -1 }">首页</li>
          <li
            @click="goProject(v)"
            :class="{ act: activeId === v.code }"
            v-for="(v, i) in baseInfo.navigationProjectLists"
            :key="i"
          >
            {{ v.name }}
          </li>
          <li
            @click="goPath(v)"
            :class="{ act: activeId === v.name }"
            v-for="(v, i) in baseInfo.navigationFixLists"
            :key="i + 99"
          >
            {{ v.name }}
          </li>
        </ul>
      </div>
    </van-popup>
  </header>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      // isLogin: true,
      userMenu: false,
      navMenu: false,
      activeId: -1,
      baseInfo: {},
      userInfo: {},
      isEnvNoticeShow: false, // 环境提示
      envNotice: "",
      menuLists: [], // 菜单列表
    };
  },
  watch: {
    navMenu(val) {
      this.$bus.$emit("hideVideo", val);
    },
  },
  mounted() {
    console.log(this.isLogin, "哈哈哈登录了");
    document.addEventListener("click", () => {
      this.userMenu = false;
    });
    this.getBaseInfo();
    this.getUserInfo();
  },
  computed: {
    ...mapState(["isLogin"]),
  },

  created() {
    // 判断环境
    let env = process.env["NODE_ENV"];
    if (env === "test") {
      this.isEnvNoticeShow = true;
      this.envNotice = "test";
    } else if (env === "pre") {
      this.isEnvNoticeShow = true;
      this.envNotice = "stage";
    } else if (env === "mock") {
      this.isEnvNoticeShow = true;
      this.envNotice = "Mock";
    }
  },
  methods: {
    ...mapActions(["SET_LOGIN"]),
    getUserInfo() {
      this.$Api.useInfo().then(({ code, data }) => {
        this.userInfo = data;
        // 菜单列表
        this.menuLists = data?.menuLists || [];
        this.SET_LOGIN(true);
      });
    },
    toPath(val) {
      if (val === "/") {
        this.activeId = -1;
      }
      this.$router.push({
        path: val,
      });
      this.navMenu = false;
    },
    // 前往项目详情页面
    goProject(nav) {
      this.$router.push({
        path: "/project-detail/" + nav.code,
      });
      this.activeId = nav.code;
      this.navMenu = false;
    },
    showMenu() {
      this.navMenu = !this.navMenu;
    },
    goPath(nav) {
      this.activeId = nav.name;
      console.log(this.activeId);
      if (nav.code === "kechengshangcheng") {
        this.$router.push("/course-detail");
      } else if (nav.code === "jianjie") {
        this.$router.push("/about-us");
      } else {
        this.$openLink(nav.href);
      }
      // this.activeId = v.code
      this.navMenu = false;
    },
    getBaseInfo() {
      this.$Api.indexBaseInfo().then(({ code, data }) => {
        this.baseInfo = data;

        // document.querySelector("link[type='image/x-icon']").href = data?.site?.favicon || ''
        document
          .querySelector("meta[name='keywords']")
          .setAttribute("content", data.site.keywords);
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", data.site.description);
        document.title = data.site.title;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#sywap-header {
  width: 100vw;
  height: 88px;
  background: @black-color;
  padding: 0 30px;
  display: flex;
  align-items: center;
  color: #323c32;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;

  & > div {
    display: flex;
    align-items: center;
  }

  .left {
    font-size: 18px;
  }

  .right {
    font-size: 24px;

    & > div {
      display: flex;
      align-items: center;
    }
  }

  .icon-1 {
    width: 40px;
    // height: 33px;
    margin-right: 27px;
    margin-top: 4px;
  }

  .icon-2 {
    width: 54px;
    height: 54px;
    margin-right: 15px;
  }

  .icon-3 {
    width: 136px;
    // height: 48px;
    height: auto;
  }

  .icon-4 {
    width: 54px;
    height: 54px;
    margin: 0 14px;
  }

  .icon-5 {
    width: 26px;
    height: 17px;
    transform: rotate(180deg);
    transition: all 0.2s linear;

    &.act {
      transform: rotate(0);
    }
  }

  .login-btn {
    color: @primary-color;
  }

  .user-menu {
    // width: 136px;
    // height: 199px;
    background: #ffffff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 30px 20px;
    color: @black-color2;
    position: absolute;
    right: 29px;
    top: 78px;
    z-index: 9;

    & > div {
      margin-bottom: 20px;
      line-height: 33px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .nav-menu {
    width: 313px;
    height: 100vh;
    background: #fff;
    padding: 30px 40px;
    padding-top: 82px;

    h4 {
      font-size: 40px;
      font-weight: 500;
      color: #323c32;
      line-height: 56px;
      margin-bottom: 27px;
    }

    li {
      position: relative;
      line-height: 100px;
      font-size: 30px;
      color: #323c32;

      &.act {
        &::after {
          content: "";
          width: 12px;
          height: 53px;
          background: #00cd88;
          position: absolute;
          left: -40px;
          top: 22px;
        }
      }
    }
  }
}
.env-notice {
  font-size: 12px;
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 0px;
  text-align: center;
  bottom: -10px;
  color: #f56c6c;
}

.env-notice .el-icon-warning-outline {
  color: #e6a23c;
}
</style>
