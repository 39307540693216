import "../assets/less/global.less";

import "./directive";

import Vue from "vue";
// import Vant from "vant";
// import "vant/lib/index.css";

// import {
//     Swipe,
//     SwipeItem,
//     Button,
//     Tabbar,
//     TabbarItem,
//     Popup,
//     Icon,
//     DropdownMenu,
//     DropdownItem,
//     ActionSheet,
//     Overlay,
//     Sticky
// } from "vant";

// import { Tabbar, TabbarItem } from "vant";
// import { Popup } from "vant";
// import { Icon } from 'vant';
// import { DropdownMenu, DropdownItem } from "vant";
// import { ActionSheet } from 'vant';
// import { Overlay } from 'vant';
// import { Sticky } from 'vant';

// import { Carousel ,CarouselItem , Image } from 'element-ui';

import * as Api from "@/api";
import { utils } from "./utils.js"; //公共js

// Vue.use(Vant);
// Vue.use(Overlay);
// Vue.use(Icon);
// Vue.use(ActionSheet);
// Vue.use(DropdownMenu);
// Vue.use(DropdownItem);
// Vue.use(Popup);
// Vue.use(Tabbar);
// Vue.use(TabbarItem);
// Vue.use(Button);
// Vue.use(Swipe);
// Vue.use(SwipeItem);
// Vue.use(Sticky);
// Vue.use(Carousel);
// Vue.use(CarouselItem);
// Vue.use(Image);

Vue.prototype.$utils = utils;

Vue.prototype.$openLink = (link) => window.open(link);
Vue.prototype.$Api = Api;

Vue.prototype.$bus = new Vue();
