<template>
  <main v-if="projectData" style="padding-bottom:60px">
    <section id="p_part1">
      <h5 class="section-title">
        <span class="t">{{ projectData.detail[0].name }}</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
      </h5>
      <div class="content">
        <img alt="" src="">
        <div class="tabs">
          <Tabs v-model="acitves[0]" :datas='classes[0]' @change="()=>getDatas(0)"></Tabs>
          <div class="items style1">
            <div v-for="(d0,i) in datas[0]" :key="i" @click="$openLink(d0.courseLessonUrl)">
              <!-- <img src="@img/test/01.jpg" alt="">
              <div class="des">研究生考试面试</div> -->
              <img :src="d0.middlePicture"/>
              <div class="des">{{ d0.title }}</div>
            </div>
          </div>
          <div v-if="datas[0].length ===0">暂无数据</div>
        </div>
      </div>
    </section>
    <section id="p_part2">
      <h5 class="section-title">
        <span class="t">{{ projectData.detail[1].name }}</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
      </h5>
      <div class="content">
        <img alt="" src="">
        <div class="tabs">
          <Tabs v-model="acitves[1]" :datas='classes[1]' @change="()=>getDatas(1)"></Tabs>
          <div class="items style1">
            <div v-for="(d0,i) in datas[1]" :key="i" @click="$openLink(d0.courseLessonUrl)">
              <img :src="d0.middlePicture"/>
              <div class="des">{{ d0.title }}</div>
            </div>
            <div v-if="datas[1].length ===0">暂无数据</div>
          </div>
        </div>
      </div>
    </section>
    <section id="p_part3">
      <h5 class="section-title" @click="goCourseDetail">
        <span class="t">{{ projectData.detail[2].name }}</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
      </h5>
      <div class="content">
        <img alt="" src="">
        <div class="tabs">
          <Tabs v-model="acitves[2]" :datas='classes[2]' @change="()=>getDatas(2)"></Tabs>
          <div class="items style1">
            <div v-for="(d0,i) in datas[2]" :key="i" @click="$openLink(d0.classroomMallUrl)">
              <img :src="d0.middlePicture"/>
              <div class="des">{{ d0.title }}</div>
            </div>
            <div v-if="datas[2].length ===0">暂无数据</div>
          </div>
        </div>
      </div>
    </section>
    <section id="p_part4">
      <h5 class="section-title">
        <span class="t">{{ projectData.detail[3].name }}</span>
        <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
      </h5>
      <div class="content">
        <img alt="" src="">
        <div class="tabs">
          <Tabs v-model="acitves[3]" :datas='classes[3]' @change="()=>getDatas(3)"></Tabs>
          <div class="items">
            <div class="style2">
              <div v-for="(d0,i) in datas[3]" :key="i" @click="$openLink(d0.articleUrl)">
                <div class="tag">HOT</div>
                <div class="title">{{ d0.title }}</div>
                <div class="text overflow3" v-html="d0.body">
                </div>
                <div class="des">相关资讯</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Tabbar></Tabbar>
  </main>
</template>

<script>
import Tabs from "@com/Tabs.vue";
import Tabbar from "@com/Tabbar.vue";
import axios from "axios";

export default {
  components: {Tabs, Tabbar},
  data() {
    return {
      tab1: "1",
      projectData: {},
      classes: {
        0: [],
        1: [],
        2: [],
        3: [],
      },

      acitves: {
        0: "",
        1: "",
        2: "",
        3: "",
      },
      datas: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      loadings: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
    };
  },
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    goCourseDetail() {
      this.$router.push({
        path: "/course-detail",
        query: {
          code: this.projectData.detail[2].code
        }
      })
    },
    init() {
      this.projectCode = this.$route.params.id;
      this.initData()
      this.getColumnDetail();

    },
    initData() {
      this.projectData = {}
      this.classes = {
        0: [],
        1: [],
        2: [],
        3: [],
      }

      this.acitves = {
        0: "",
        1: "",
        2: "",
        3: "",
      }
      this.datas = {
        0: [],
        1: [],
        2: [],
        3: [],
      }
      this.loadings = {
        0: false,
        1: false,
        2: false,
        3: false,
      }
      // this.$forceUpdate()
    },
    getColumnDetail() {
      this.$Api
          .columnDetail({projectCode: this.projectCode})
          .then(({data}) => {
            this.projectData = data;
            for (const act in this.acitves) {
              this.acitves[act] = data.detail[act].children[0].dataUrl;
              data.detail[act].children.forEach((c) => {
                this.classes[act].push({
                  label: c.name,
                  value: c.dataUrl,
                });
              });
              this.getDatas(act);
            }
            this.$nextTick(() => {
              const index = this.$route.query.index;
              const code = this.$route.query.code;
              if (index) {
                // console.log(data.detail[index-1])
                if (
                    data.detail[index - 1].children.filter((i) => i.code === code)
                        .length > 0
                ) {
                  this.acitves[index - 1] = data.detail[
                  index - 1
                      ].children.filter((i) => i.code === code)[0].dataUrl;
                }
                this.getDatas(index - 1);
                // console.log(document.querySelector(`#p_part${index}`).offsetTop);
                setTimeout(() => {
                  document.documentElement.scrollTop =
                      document
                          .querySelector(`#p_part${index}`)
                          .getBoundingClientRect().top - 80;
                }, 200);
                // console.log(document.querySelector(`#p_part${index}`));
              }
            });
            // this.projectData.detail[0].dataUrl
          });
    },
    getColumnOpenCourseList() {
      this.$Api
          .columnOpenCourseList({projectCode: this.projectCode})
          .then(({code, data}) => {
            this.openCourseList = data;
          });
    },
    getDatas(type) {
      // const types = {
      //   1:''
      // }
      // if(this.datas[type].length > 0) return;
      this.loadings[type] = true;
      // console.log(this.acitves[type]);
      axios.get(this.acitves[type]).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          this.datas[type] = res.data.data.list;
        }
        this.loadings[type] = false;
      });
      this.$forceUpdate();
      // console.log(this.acitves, type, this.datas);
    },
  },
};
</script>