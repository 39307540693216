<template>
  <main>
    <van-dropdown-menu active-color="#0DD280">
      <van-dropdown-item v-model="params.categoryId" :options="categories"/>
      <van-dropdown-item v-model="params['fees']" :options="cfgs['2']" title="学费"/>
      <van-dropdown-item v-model="params['educationalSystem']" :options="cfgs['3']" title="院校特征"/>
      <van-dropdown-item ref="filterRef" v-model="params['4']" :options="[]" title="筛选" @open="openFilter"/>
    </van-dropdown-menu>
    <div class="container">
      <div class="search-wrap">
        <input v-model="params.collegeName" placeholder="请输入想要查找的院校名称" type="text">
        <button @click="search">
          <span>立即查询</span>
          <img alt="" src="@img/icon-search-1.png" style="width:14px;">
        </button>
      </div>
      <div class="school-list">
        <div v-for="(coll,i) in collegeLists" :key="i" class="item" @click="toCollege(coll)">
          <img :src="coll.collegeLogo" alt="" class="logo">
          <div>
            <div class="title">{{ coll.collegeName }}</div>
            <div>
              <span v-if="coll.collegeFeatures.indexOf('985') > -1" class="tag blue">985</span>
              <span v-if="coll.collegeFeatures.indexOf('211') > -1" class="tag yellow">211</span>
              <span v-if="coll.collegeFeatures.indexOf('双一流') > -1" class="tag yellow y1">双一流</span>
            </div>
            <div class="des">
              <span>地区: {{ coll.areaName }}</span>
              <span>学习费用: {{ coll.feesText }}</span>
            </div>
            <div v-if="coll.videoUrl" class="mba">
              <img alt="" src="@img/icon-searchdetail-1.png">
              <span>MBA院校直通车</span>
            </div>
          </div>
        </div>
        <div v-if="collegeLists.length === 0" class="no-result">
          <img :src="$utils.CDNURL+'/img/no-result.png'" alt="">
          <p>没有搜索结果</p>
        </div>
      </div>
    </div>
    <van-popup v-model="filterMenu" position="right">
      <div class="filter-menu">
        <div class="t">筛选</div>
        <div class="content">
          <div :class="{act:actives[0]}" class="row">
            <div class="row-t">地区
              <div @click="closeRow(0)">
                <img alt="" src="@img/icon-select.png">
              </div>
            </div>
            <SelectBtn v-model="params['areaName']" :datas="cfgs['1']" valueName="value"></SelectBtn>
          </div>
          <div :class="{act:actives[1]}" class="row">
            <div class="row-t">学费

              <div @click="closeRow(1)">
                <img alt="" src="@img/icon-select.png">
              </div>
            </div>
            <SelectBtn v-model="params['fees']" :datas="cfgs['2']" valueName="value"></SelectBtn>
          </div>
          <div :class="{act:actives[2]}" class="row">
            <div class="row-t">学制

              <div @click="closeRow(2)">
                <img alt="" src="@img/icon-select.png">
              </div>
            </div>
            <SelectBtn v-model="params['educationalSystem']" :datas="cfgs['3']" valueName="value"></SelectBtn>
          </div>
          <div :class="{act:actives[3]}" class="row">
            <div class="row-t">提前面试

              <div @click="closeRow(3)">
                <img alt="" src="@img/icon-select.png">
              </div>
            </div>
            <SelectBtn v-model="params['aheadInterview']" :datas="cfgs['4']" valueName="value"></SelectBtn>
          </div>
        </div>
        <div class="actions">
          <button @click="reset">重置</button>
          <button @click="search">确定</button>
        </div>
      </div>
    </van-popup>
  </main>
</template>

<script>
import * as Api from "@/api";
import SelectBtn from "@com/SelectBtn.vue";

export default {
  components: {SelectBtn},
  data() {
    return {
      cfgs: {},
      params: {
        categoryId: null,
        areaName: null,
        collegeName: null,
      },
      categories: [],
      collegeLists: [],
      filterMenu: false,
      actives: {0: false, 1: false, 2: false, 3: false},
    };
  },
  mounted() {
    this.params.collegeName = this.$route.query.collegeName;
    this.params.areaName = this.$route.query.areaName || null;
    this.params.categoryId = this.$route.query.categoryId;

    this.getSearchConfig();
    this.getCollegeCategories();
    this.search();
  },
  methods: {
    closeRow(index) {
      this.actives[index] = !this.actives[index];
      this.$forceUpdate();
    },
    getCollegeCategories() {
      this.$Api.index().then(({code, data}) => {
        data.collegeLibrary["collegeLists"].forEach((d) => {
          d.value = d.id;
          d.text = d.name;
        });
        this.categories = data.collegeLibrary["collegeLists"];
      });
    },
    getSearchConfig() {
      Api.collegeListConfig().then((res) => {
        if (res) {
          for (let i in res.data) {
            this.$utils.getDropDownValue(res.data[i], ["value", "value"]);
          }
          this.cfgs = Object.assign({}, this.cfgs, res.data);
        }
      });
    },
    openFilter() {
      this.filterMenu = true;
      this.$nextTick(() => {
        this.$refs["filterRef"].toggle(false);
      });
    },
    search() {
      this.filterMenu = false;
      this.$Api.collegeList(this.params).then(({code, data}) => {
        this.collegeLists = data.list;
      });
    },
    toCollege(data) {
      // console.log(this.categories, this.params.categoryId);
      this.$router.push({
        path: `/school-detail/${data.id}`,
        query: {
          categoryName: this.categories.filter(
              (it) => it.value === this.params.categoryId
          )[0].id,
          categoryId: this.params.categoryId,
        },
      });
    },
    reset() {
      for (const key in this.params) {
        if (key === "mba") {
          continue;
        }
        this.params[key] = null;
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="less" scoped>
main {
  background: #fff;
}

.container {
  padding: 0 30px;
}

.search-wrap {
  margin: 40px 0;
  position: relative;

  input {
    width: 690px;
    height: 70px;
    background: #f3f3f3;
    border-radius: 10px;
    padding-right: 210px;
    padding-left: 20px;
    font-size: 24px;
    background: #f3f3f3;

    &::placeholder {
      color: #aaadaa;
    }
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    width: 210px;
    height: 70px;
    background: @primary-color;
    border-radius: 0 10px 10px 0;
    font-size: 30px;
    color: #fff;

    img {
      padding-left: 6px;
      width: 25px;
      //   height: 22px;
    }
  }
}

.school-list {
  .item {
    display: flex;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #f3f3f3;

    .logo {
      width: 167px;
      height: 167px;
      margin-right: 20px;
      border-radius: 50%;
    }

    .title {
      font-size: 34px;
      font-weight: 500;
      color: #323c32;
      line-height: 48px;
      margin-bottom: 5px;
    }

    .tag {
      width: 62px;
      height: 28px;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      display: inline-block;
      margin-right: 20px;
      border-radius: 8px;

      &.blue {
        background: #d8ecff;
        color: #2c82fe;
      }

      &.yellow {
        background: #ffedd7;
        color: #ff7c02;

        &.y1 {
          width: 82px;
        }
      }
    }

    .des {
      font-size: 24px;
      font-weight: 400;
      color: #898d89;
      line-height: 33px;
      margin: 5px 0;

      & > * {
        margin-right: 20px;
      }
    }

    .mba {
      color: @primary-color;
      font-size: 24px;
      display: flex;
      align-items: center;

      img {
        margin-right: 7px;
        width: 33px;
        height: 29px;
      }
    }
  }
}

.filter-menu {
  width: 510px;
  background: #fff;
  height: 100vh;
  padding-bottom: 100px;

  .t {
    width: 100%;
    height: 70px;
    background: #f3f3f3;
    padding: 0 30px;
    display: flex;
    align-items: center;
    font-size: 30px;
  }

  .content {
    padding: 0 30px;
    height: calc(~"100vh - 200px");
    overflow-y: auto;

    .row {
      border-bottom: 1px solid #f3f3f3;
      margin-bottom: 30px;
      transition: all 0.2s linear;
      max-height: max-content;

      &.act {
        height: 322px;
        overflow: hidden;

        .row-t {
          img {
            transform: scale(0.5) rotate(180deg);
          }
        }
      }

      .row-t {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 28px;
        font-size: 24px;
        font-weight: bold;

        img {
          transform: scale(0.5);
          transition: all 0.2s linear;
        }
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;

    button {
      width: 50%;
      height: 90px;
      font-size: 30px;

      &:first-child {
        color: @primary-color;
        background: rgba(13, 210, 128, 0.1);
      }

      &:last-child {
        background: @primary-color;
        color: #fff;
      }
    }
  }
}

.filter-menu .content .row.act {
  height: 22.933vw;
  overflow: hidden;
}
</style>