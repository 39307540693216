<template>
  <main>

    <div style="min-height:100vh">
      <van-dropdown-menu active-color="#0DD280">
        <van-dropdown-item v-model="params['projectCode']" :options="cfgs['projectLists']" @change="search1"/>
        <van-dropdown-item v-model="params['categoryCode']" :options="computed_categoryLists" title="分类"
                           @change="search2"/>
      </van-dropdown-menu>
      <div class="course-list">
        <div v-for="l in l_datas.lists" :key="l.id" class="item" @click="$openLink(l.href)">
          <img :src="l.middlePicture" class="cover">
          <div class="des">{{ l.title }}</div>
          <div class="price"><span class="pre">￥</span> {{ l.price }}</div>
        </div>
        <div v-if="l_datas.lists.length === 0" class="no-result">
          <img :src="$utils.CDNURL+'/img/no-result.png'" alt="">

          <p>没有搜索结果</p>
        </div>
      </div>
    </div>
    <Tabbar></Tabbar>
  </main>
</template>

<script>
import * as Api from "@/api";
import Tabbar from "@com/Tabbar.vue";

export default {
  components: {Tabbar},
  data() {
    return {
      projectCode: null,
      categoryCode: null,
      l_datas: {},
      cfgs: {},
      params: {
        page: 1,
        categoryCode: null,
        projectCode: null,
      },
    };
  },
  computed: {
    computed_categoryLists() {
      const list = this.cfgs.projectLists.filter(i => i.code === this.params.projectCode)
      if (list.length > 0) {
        this.$utils.getDropDownValue(list[0].categoryLists, ["name", "code"]);
        // console.log(list[0].categoryLists);
        return list[0].categoryLists
      }
      return []
    }
  },
  mounted() {
    const code = this.$route.query.code;

    this.params.projectCode = code;

    this.getCfgs();
  },
  methods: {
    getCfgs() {
      Api.classroommallCategory().then((res) => {
        // for (let i in res.data) {
        //   this.$utils.getDropDownValue(res.data[i], ["name", "code"]);
        // }
        this.$utils.getDropDownValue(res.data.projectLists, ["name", "code"]);
        //this.$utils.getDropDownValue(res.data.categoryLists, ["name", "code"]);

        this.cfgs = res.data;
        if (
            res.data.projectLists &&
            res.data.projectLists.length > 0 &&
            !this.params.projectCode
        ) {
          this.params.projectCode = res.data.projectLists[0].code;
        }
        this.getIndex();
      });
    },
    getIndex(params = {}) {
      // console.log(this.params, params)
      this.$Api
          .classroommallIndex(Object.assign(this.params, params))
          .then((res) => {
            this.l_datas = res.data;
          });
    },
    getCategory() {
      this.$Api.classroommallCategory().then((res) => {
        this.categorys = res.data;
      });
    },
    pageChange(page) {
      this.getIndex({page});
    },
    search1() {
      this.params.categoryCode = ''
      this.getIndex();
    },
    search2() {
      this.getIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.course-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 30px;
  background: #fff;
  padding-bottom: 120px;

  .item {
    width: 330px;
    margin-bottom: 30px;

    .cover {
      width: 330px;
      height: 220px;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 10px;
    }

    .des {
      font-size: 24px;
      font-weight: 400;
      color: #898d89;
      line-height: 33px;
      margin-bottom: 11px;
      word-break: break-all;
    }

    .price {
      color: #ff655b;
      font-size: 30px;

      .pre {
        font-size: 18px;
      }
    }
  }
}
</style>