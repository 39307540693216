import Vue from "vue";
import VueRouter from "vue-router";
import index from "./views/index.vue";//域名
import about from "./views/about.vue";
import projectDetail from "./views/project-detail.vue";
import searchDetail from "./views/search-detail.vue";
import courseDetail from "./views/course-detail.vue";
import schoolDetail from "./views/school-detail.vue";
// 创建订单 - 花呗分期
import createOrder from './views/create-order/CreateOrder.vue'
// 创建订单 - 花呗分期 -详情
import createOrderDetail from './views/create-order/CreateOrderDetail.vue'
// 创建订单 - 聚合支付
import createOrderAgg from './views/create-order-agg/CreateOrder.vue'
// 创建订单 - 聚合支付-详情
import createOrderAggDetail from './views/create-order-agg/CreateOrderDetail.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect:"/index"
  },
  {
    path: "/index",
    name: "Index",
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "./views/index.vue"),
    component: index
  },
  {
    path: "/about-us",
    name: "About",
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "./views/about.vue"),
    component: about
  },
  {
    path: "/project-detail/:id",
    name: "ProjectDetail",
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "./views/project-detail.vue"),
    component: projectDetail
  },
  {
    path: "/search-detail",
    name: "SearchDetail",
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "./views/search-detail.vue"),
    component: searchDetail
  },
  {
    path: "/course-detail",
    name: "CourseDetail",
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "./views/course-detail.vue"),
      component: courseDetail
  },
  {
    path: "/school-detail/:id",
    name: "SchoolDetail",
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "./views/school-detail.vue"),
    component: schoolDetail
  },
  // 创建订单 - 花呗分期
  {
    path: "/create-order",
    name: "CreateOrder",
    component: createOrder
  },
  // 创建订单 - 花呗分期 - 详情
  {
    path: "/create-order-detail",
    name: "CreateOrderDetail",
    component: createOrderDetail
  },
  // 创建订单 - 聚合支付
  {
    path: "/create-order-agg",
    name: "CreateOrderAgg",
    component: createOrderAgg
  },
  // 创建订单 - 聚合支付 - 详情
  {
    path: "/create-order-agg-detail",
    name: "CreateOrderAggDetail",
    component: createOrderAggDetail
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
