<!-- 金牌讲师 -->
<template>
  <div class="swiperTeaching3">
    <div class="swiper-container" id="swiper3">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in goldTeacherListData"
          :key="index"
        >
          <img :src="item.avatar" class="bookImg" />
          <div class="describe">
            <p class="name">
              {{ item.name }}
            </p>
            <p class="position">
              {{ item.position }}
            </p>
            <div class="descriptionDiv"></div>
            <p class="description">
              {{ item.description }}
              <!-- 教育产品负责人。北京工业大学硕士，曾在微软中国公司担任微软开发者网络（MSDN）主编，曾在Adobe中国担任数字市场解决方案架构师，对教育解决方案研发具有深入理解，有多年的教育、市场、产品相关经验。 -->
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination2"></div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";
export default {
  data() {
    return {
      goldTeacherListData: [], // 数据列表
      bookSwiper: [],
    };
  },
  created() {
    // rem适配
    const setFontSize = () => {
      var html = document.documentElement;
      var windowWidth = html.clientWidth;
      if (windowWidth <= 750) {
        html.style.fontSize = windowWidth / 7.5 + "px";
      } else {
        html.style.fontSize = "100px";
      }
    };
    window.onresize = setFontSize;
    setFontSize();
  },
  mounted() {
    this.$Api.goldTeacherList().then((res) => {
      this.goldTeacherListData = res.data;
      setTimeout(() => {
        new Swiper("#swiper3", {
          direction: "horizontal",
          speed: 600,
          loop: true,
          autoplay: false,
          observer: true,
          observeParents: true,
          effect: "slide",
          slidesPerView: "auto",
          centeredSlides: true,
          slideToClickedSlide: true,
          pagination: {
            el: ".swiper-pagination2",
          },
        });
      }, 100);
    });
  },
};
</script>

<style lang="less">
.swiperTeaching3 {
  margin-bottom: 0.5rem;
  padding: 0 0.3rem 0 0.3rem;
  .describe {
    margin-left: 0.68rem;
  }
  #swiper3 {
    width: 100%;
    margin-top: 0.3rem;
  }
  .swiper-wrapper {
    border-radius: 0.1rem;
  }
  .swiper-pagination2 {
    width: 100%;
    margin-top: 0.3rem;
    justify-content: center;
    display: flex;
    --swiper-pagination-color: #00ff33; /* 两种都可以 */
    .swiper-pagination-bullet {
      margin-right: 0.1rem;
      width: 0.13rem;
      height: 0.13rem;
    }
    .swiper-pagination-bullet-active {
      background: #0dd280;
    }
  }
  .swiper-slide {
    width: 6.9rem;
    height: 3.96rem;
    border-radius: 0.1rem 0.1rem 0 0;
    position: relative;
    background: #f8f8f8;
    border-radius: 0.1rem;
    padding: 0.52rem;
    display: flex;
  }
  .bookImg {
    width: 1.58rem !important;
    height: 2.92rem;
  }
  .description {
    height: 1.57rem;
    color: rgba(137, 141, 137, 1);
    font-size: 0.18rem;
    text-align: justify;
    line-height: 0.26rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .position {
    font-size: 0.26rem;
    margin-bottom: 0.15rem;
  }
  .descriptionDiv {
    width: 0.15rem;
    height: 0.02rem;
    background: #0dd280;
    margin-bottom: 0.18rem;
  }
  .name {
    margin-top: 0.13rem;
    font-size: 0.36rem;
  }
}
</style>
