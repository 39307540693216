import axios from 'axios'
// import Cookies from 'js-cookie'

// 测试地址
const BASE_URL = process.env.VUE_APP_BASE_HOST

// 联调环境地址
// const BASE_URL = process.env.VUE_APP_ORDER_URL

// 创建 request
const request = axios.create({
  baseURL: BASE_URL,
  timeout: 5000
})

request.defaults.withCredentials = true

// 请求拦截器
request.interceptors.request.use(
  function (config) {
    // const followUserid = Cookies.get('followUserid') || ''
    config.headers = {
      // 'USER-ID': followUserid,
      'Content-Type': 'application/json;charset=UTF-8'
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export { request }
