<template>
  <div id="app">
    <Header v-show="!isCreateOrder"></Header>
    <router-view />
    <!-- <Tabbar></Tabbar> -->
    <VideoPlay v-show="!isCreateOrder"></VideoPlay>
  </div>
</template>
<script>
import VideoPlay from "./components/VideoPlay.vue";
import Header from "./components/page-header.vue";
export default {
  components: { Header, VideoPlay },
  data() {
    return {
      isCreateOrder: false, // 是否是创建订单页面
    }
  },
  created() {
    // 判断是否是创建订单页面
    this.isCreateOrder =
      this.$route.name === "CreateOrder" ||
      this.$route.name === "CreateOrderDetail" ||
      this.$route.name === "CreateOrderAgg" ||
      this.$route.name === "CreateOrderAggDetail"
        ? true
        : false;
    if (!this.isCreateOrder) {
      document.getElementById("app").style.paddingTop = "88px";
    }
  },
};
</script>
<style lang="less" scoped>
// #app {
//   padding-top: 88px;
// }
</style>
