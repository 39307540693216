<!-- 院校详情 -->
<template>
  <main v-if="collegeData">
    <template>
      <video v-if="videoUrl" ref="schoolVideo" autoplay controls @timeupdate="timeupdate"></video>
    </template>
    <van-sticky v-show="showNav" :offset-top="offset">
      <div class="tab-wrap">
        <Tabs v-model="activeNav" :datas="navs" :href="true" active></Tabs>
      </div>
    </van-sticky>
    <div
        :class="{ hasVideo: collegeData.collegeBase.videoUrl }"
        class="container"
    >
      <section id="jiben" class="one">
        <h5 id="st1" class="section-title">
          <span class="t">基本信息</span>
          <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
        </h5>
        <div class="content">
          <div class="flex-align">
            <img
                :src="collegeData.collegeBase.collegeLogo"
                alt=""
                class="school-logo"
            />
            <span class="school-title">{{
                collegeData.collegeBase.collegeName
              }}</span>
          </div>
          <div class="infos">
            <ul>
              <li>
                <span>地区 : </span>
                <span>{{ collegeData.collegeBase.areaName }}</span>
              </li>
              <li>
                <span>创办时间 : </span>
                <span>{{ collegeData.collegeBase.createDate }} </span>
              </li>
              <li>
                <span>分数线类别 : </span>
                <span>{{ collegeData.collegeBase.scoreLineType }}</span>
              </li>
              <li>
                <span>性质 : </span>
                <span
                >{{ collegeData.collegeBase.collegeFeatures.join(" ") }}
                </span>
              </li>
              <li>
                <span>地址 : </span>
                <span>{{ collegeData.collegeBase.address }}</span>
              </li>
              <li>
                <span>官网 : </span>
                <span>{{ collegeData.collegeBase.orgUrl }} </span>
              </li>
              <li>
                <span>认证 : </span>
                <span>{{ collegeData.collegeBase.attest.join(" ") }}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section id="zhaosheng" class="two">
        <h5 id="st2" class="section-title">
          <span class="t">招生信息</span>
          <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
        </h5>
        <div class="content">
          <table>
            <tr>
              <td class="bg">项目类别</td>
              <td>{{ collegeData.admissionsInfo.category.join("") }}</td>
              <td class="bg">学制</td>
              <td>{{ collegeData.admissionsInfo.educationalSystem }}</td>
              <td class="bg">是否接受调剂</td>
              <td>{{ collegeData.admissionsInfo.isRegulate }}</td>
            </tr>
            <tr>
              <td class="bg">提彰面试</td>
              <td colspan="2">
                {{ collegeData.admissionsInfo.aheadInterview }}
              </td>
              <td class="bg">招生人数</td>
              <td colspan="2">
                {{ collegeData.admissionsInfo.admissionsNumber }}
              </td>
            </tr>
            <tr>
              <td class="bg">学费</td>
              <td colspan="5">
                <span v-html="collegeData.admissionsInfo.fees"></span>
              </td>
            </tr>
            <tr>
              <td class="bg">奖学金</td>
              <td colspan="5">
                <span v-html="collegeData.admissionsInfo.scholarships"></span>
              </td>
            </tr>
            <!-- <tr>
              <td class="bg">历年招生数据</td>
              <td colspan="5" class="has-table">
              </td>
            </tr> -->
          </table>
        </div>
        <h5 class="section-title">
          <span class="t">历史分数线</span>
        </h5>
        <div class="content">
          <div class="scores">
            <div
                v-for="(l, i) in collegeData.admissionsInfo.calendarScoreLine"
                :key="i"
                class="green"
            >
              <div class="year">{{ l.year }}</div>
              <div>
                <div>综合: {{ l.chinese }}</div>
                <div>英语: {{ l.english }}</div>
                <div>总分: {{ l.TotalScore }}</div>
              </div>
            </div>
            <!-- <div class="yellow">
              <div class="year">2020</div>
              <div>
                <div>综合: 99</div>
                <div>英雄: 99</div>
                <div>总分: 99</div>
              </div>
            </div>
            <div class="red">
              <div class="year">2019</div>
              <div>
                <div>综合: 99</div>
                <div>英雄: 99</div>
                <div>总分: 99</div>
              </div>
            </div> -->
          </div>
        </div>
        <h5 class="section-title">
          <span class="t">历史招生数据</span>
        </h5>
        <div class="content">
          <table>
            <tr>
              <td class="bg">年份</td>
              <td class="bg">报考人数</td>
              <td class="bg">实考人数</td>
              <td class="bg">录取人数</td>
              <td class="bg">录取比例</td>
            </tr>
            <tr
                v-for="(c, i) in collegeData.admissionsInfo
                .calendarRecruitStudents"
                :key="i"
            >
              <td>{{ c.year }}</td>
              <td>{{ c.applyNum }}</td>
              <td>{{ c.actualNum }}</td>
              <td>{{ c.enrollNum }}</td>
              <td>{{ c.enrollRate }}</td>
            </tr>
          </table>
        </div>
      </section>
      <section id="yuanxiao" class="three">
        <h5 id="st3" class="section-title">
          <span class="t">院校特色</span>
          <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
        </h5>
        <div class="content text">
          <div>
            <div v-html="collegeData.collegeIntroduction"></div>
          </div>
          <!-- {{collegeData.collegeIntroduction}} -->
        </div>
      </section>
      <section id="shizi" class="four">
        <h5 id="st4" class="section-title">
          <span class="t">师资队伍</span>
          <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
        </h5>
        <div class="content">
          <div
              aria-disabled="false"
              aria-label="Previous slide"
              class="swiper-button-prev swiper-button-white"
              data-v-0860b020=""
              role="button"
              tabindex="0"
          ></div>
          <ul>
            <li
                v-for="(t, i) in collegeData.teachers"
                :key="i"
                :style="`background-image:url(${t.avatar})`"
                class="szdw"
            >
              <!-- <img :src="t.avatar" alt=""> -->
              <div class="text">{{ t.teacherName }}</div>
            </li>
          </ul>
        </div>
      </section>
      <section id="shenqing" class="five">
        <h5 id="st5" class="section-title">
          <span class="t">申请流程</span>
          <img alt="" class="icon-section" src="@img/icon-index-6.png"/>
        </h5>
        <div class="content text">
          <div>
            <div v-html="collegeData.applyFlow"></div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import axios from "axios"
import Tabs from "@com/Tabs.vue";
// 格式化 hls 视频, 检测hls视频地址是否过期
// import {getStream, checkHlsUrl} from "@/core/utils.js";
import {getStream} from "@/core/utils.js";

export default {
  components: {Tabs},
  data() {
    return {
      hls: "", // hls视频对象
      videoUrl: "", // 视频播放地址
      categoryId: "",
      collegeId: "",
      collegeData: null,
      activeNav: "基本信息",
      offset: 0,
      showNav: true,
      navs: [
        {label: "基本信息", value: "基本信息", href: "#jiben"},
        {label: "招生信息", value: "招生信息", href: "#zhaosheng"},
        {label: "院校特色", value: "院校特色", href: "#yuanxiao"},
        {label: "师资队伍", value: "师资队伍", href: "#shizi"},
        {label: "申请流程", value: "申请流程", href: "#shenqing"},
      ],
      sendId: "",
      sumDuration: 0,
      timer: null,
      hostname: ''
    };
  },
  mounted() {
    // 初始化获取院校库详情数据
    this.collegeId = this.$route.params.id;
    this.categoryId = this.$route.query.categoryId;
    this.getSchool();
    this.$nextTick(() => {
      this.offset = document.querySelector("#sywap-header").clientHeight;
      this.$bus.$on("hideVideo", (val) => {
        // console.log(val);
        this.showNav = !val;
      });
    });
    // 监听play(), 检测hls的url是否过期
    setTimeout(() => {
      this.$refs.schoolVideo &&
      this.$refs.schoolVideo.addEventListener("play", this.handleVideoPlay);

      this.$refs.schoolVideo.addEventListener("play", () => {
        console.log("video is playing");
        if(this.$route.query.relationId && this.$route.query.resourceId && this.$route.query.uuid){
          this.openTimer();
        }
      });
      // 监听视频暂停
      this.$refs.schoolVideo.addEventListener("pause", () => {
        console.log("video is stop");
        if(this.$route.query.relationId && this.$route.query.resourceId && this.$route.query.uuid){
          this.closeTimer();
        }
      });
    }, 2000);
    if(this.$route.query.relationId && this.$route.query.resourceId && this.$route.query.uuid){
      this.openTimer()
    }
  },
  beforeDestroy() {
    // 销毁hls对象
    if (this.hls) {
      this.$refs.schoolVideo && this.$refs.schoolVideo.pause();
      this.hls.destroy();
      this.hls = null;
    }
  },
  methods: {
    // 开启定时器
    openTimer(){
      console.log('iddd')
      if(window.location.hostname.indexOf('www.cuour.org') >= 0){
        this.hostname = '//crm-api.cuour.org'
      }else if(window.location.hostname.indexOf('pre.saiyoujiaoyu.com') >= 0){
        this.hostname = '//crm-api.cuour.org'
      }else if(window.location.hostname.indexOf('test.saiyoujiaoyu.com') >= 0){
        this.hostname = '//test-crm.saiyoujiaoyu.com'
      }else{
        // this.hostname = 'http://10.31.11.110:8091'
        this.hostname = '//test-crm.saiyoujiaoyu.com'
      }
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.axiosLoop()
      }, 3000)
    },
    // 关闭定时器
    closeTimer() {
      // this.axiosLoop()
      // clearInterval(this.timer);
      console.log(this.sumDuration,'%')
      console.log('停止')
    },
    axiosLoop(){
      // let params = {
      //   relationId: this.$route.query.relationId,
      //   resourceId: this.$route.query.resourceId,
      //   uuid: this.$route.query.uuid,
      //   visitRatio: this.sumDuration
      // }
      axios.post(this.hostname+'/material/pollingRatio',{
        // params
        relationId: this.$route.query.relationId,
        resourceId: this.$route.query.resourceId,
        uuId: this.$route.query.uuid,
        visitRatio: parseInt(this.sumDuration)
      })
      .then((res) => {
          
      })
      .catch((e) => {
        console.log(e)
      })
    },
    // 监听视频的播放
    handleVideoPlay() {
      // 检测hls的url地址是否失效，404则重新请求
      // if ((this.videoUrl ?? "") !== "") {
      //   checkHlsUrl(this.videoUrl).catch((error) => {
      //     // url地址存在且请求错误，重新获取地址
      //     this.getSchool();
      //   });
      // } else {
      //   console.log("院校库详情 - 视频地址不存在");
      // }
      this.$refs.schoolVideo.play();
    },
    // 获取院校库详情
    getSchool() {
      this.$Api
          .collegeDetail({
            collegeId: this.collegeId,
          })
          .then(({code, data, message}) => {
            if (code !== 200) {
              alert(message);
            }
            this.collegeData = data;
            // 获取视频地址
            this.videoUrl = data?.collegeBase?.videoUrl;
            // 格式化video, 使其支持 hls
            this.$nextTick(() => {
              getStream(this, this.videoUrl, "schoolVideo");
            });
          });
    },
    // 获取当前播放位置
    timeupdate(e) {
      var currentTime = e.target.currentTime;
      var duration = e.target.duration.toFixed(2) ? e.target.duration.toFixed(2) : 0;
      this.sumDuration = ((currentTime/duration).toFixed(2)*100).toFixed(2)
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 750px;
  height: 422px;
  margin-bottom: 20px;
}

video {
  width: 100%;
}

table {
  border-left: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3;
  text-align: center;
  // border-collapse: separate;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 18px;

  tr {
    // padding: 10px;
    padding: 0;
  }

  .bg {
    // background-color:
    background: #f6f6f6;
  }

  td {
    padding: 20px;
    border-bottom: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    width: 200px;
  }
}

.has-table {
  padding: 0;

  table {
    border: 0;

    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }

      td:last-child {
        border-right: 0;
      }
    }
  }
}

// .container {
//   padding-top: 70px;
//   &.hasVideo {
//     padding-top: 492px;
//   }
// }

.tab-wrap {
  // padding: 0 30px;
  height: 70px;
  // padding-bottom: 60px;
  background: #f3f3f3;
  width: 100%;
}

.one {
  .content {
    flex-direction: column;
  }

  .school-logo {
    width: 167px;
    height: 167px;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 10px;
    border-radius: 50%;
  }

  .school-title {
    font-size: 40px;
    font-weight: 500;
    color: #323c32;
  }

  .infos {
    li {
      display: flex;
      font-size: 26px;
      line-height: 50px;
      align-items: center;

      & > span:first-child {
        color: #323c32;
        margin: 5px;
        width: 160px;
        text-align: right;
      }

      & > span:last-child {
        color: #898d89;
      }
    }
  }
}

// video {
//   padding-top: 70px;
// }

.two {
  .scores {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > div {
      width: 210px;
      height: 249px;
      border-radius: 10px;
      color: #898d89;
      font-size: 24px;
      padding: 30px;

      .year {
        font-size: 40px;
        line-height: 56px;
        margin-bottom: 14px;
      }

      &.green {
        background: rgba(13, 210, 128, 0.07);

        .year {
          color: #0dd280;
        }
      }

      &.yellow {
        background: rgba(253, 211, 75, 0.07);

        .year {
          color: #fdd34b;
        }
      }

      &.red {
        background: rgba(255, 85, 41, 0.07);

        .year {
          color: #ff5529;
        }
      }
    }
  }
}

.content.text {
  font-size: 24px;
  font-weight: 400;
  color: #898d89;
  line-height: 45px;
  padding: 10px 0;
}

.four {
  .content {
    overflow: auto;
  }

  ul {
    display: flex;
    margin-top: 20px;
  }

  li {
    position: relative;
    margin-right: 20px;

    img {
      width: 180px;
      height: 230px;
    }

    .text {
      position: absolute;
      bottom: 0;
      width: 180px;
      height: 40px;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
    }
  }
}

.szdw {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 180px;
  height: 230px;

  .text {
    bottom: 0;
  }
}

.has_line li:first-child {
  padding-left: 20px;
}
</style>
<style>
.van-sticky {
  background: #f3f3f3;
  padding-top: 6px;
}
</style>
