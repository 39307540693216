import axios from "axios";

axios.defaults.withCredentials=true;

const devToken = getCookie("AUTH_TOKEN") ? getCookie("AUTH_TOKEN") :'46pl7184mni8s80kcks0gk0s4oo88c8'
let loadingInstance = null; //这里是loading
//使用create方法创建axios实例
export const Service = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: process.env['VUE_APP_BASE_HOST'],
  method: "get",
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Auth-Token":
      process.env.NODE_ENV === "development"
        ? devToken
        : getCookie("AUTH_TOKEN"),
  },
});
// 添加请求拦截器
Service.interceptors.request.use((config) => {
  return config;
});
// 添加响应拦截器
Service.interceptors.response.use(
  (response) => {
    // console.log(response)
    return response.data;
  },
  (error) => {
    // console.log("TCL: error", error);
    return Promise.reject(error);
  }
);
// function getCookie(name) {
//   var strcookie = document.cookie; //获取cookie字符串
//   var arrcookie = strcookie.split("; "); //分割
//   //遍历匹配
//   for (var i = 0; i < arrcookie.length; i++) {
//     var arr = arrcookie[i].split("=");
//     if (arr[0] == name) {
//       return arr[1];
//     }
//   }
//   return "";
// }
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    // console.log(c)
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) != -1){
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// function getRequestURL(){
//   // const urlMap =  {
//   //   'refactor.cuour.org':"//www.cuour.org",
//   //   'pre-refactor.saiyoujiaoyu.com':"//pre.saiyoujiaoyu.com",
//   //   'pre-cuour-m.saiyoujiaoyu.com':"//test.saiyoujiaoyu.com"
//   // }
//   // const hostname = window.location.hostname

//   // if(urlMap[hostname]){
//   //   return urlMap[hostname]
//   // }
//   console.log(getCookie("ENV"))
//   if(window.location.hostname == 'pre-cuour-m.saiyoujiaoyu.com' || window.location.hostname == 'test.saiyoujiaoyu.com' || window.location.hostname == 'pre.saiyoujiaoyu.com'){
//     // if(getCookie("ENV") == 'beta'){
//     //   return '//test.saiyoujiaoyu.com'
//     // }else 
//     if(getCookie("ENV") == 'beta'){
//       return '//test.saiyoujiaoyu.com'
//     }else{
//       return '//pre.saiyoujiaoyu.com'
//     }
//   }
//   // else if(window.location.hostname == 'pre-cuour-m.saiyoujiaoyu.com' || window.location.hostname == 'pre.saiyoujiaoyu.com'){
//   //   return '//pre.saiyoujiaoyu.com'
//   // }
//   else if(window.location.hostname == 'refactor-m.cuour.org' || window.location.hostname == 'www.cuour.org'){
//     return '//www.cuour.org'
//   }
//   // 本地地址
//   return '//test.saiyoujiaoyu.com/'
// }