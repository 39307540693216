<template>
  <main class="about-us">
    <div class="about common-wrap">
      <CommonItem></CommonItem>
    </div>
  </main>
</template>
<script>
// 媒体采访等组件
import CommonItem from '@com/common-item.vue'
import Tabbar from '@com/Tabbar.vue'

export default {
  components: {CommonItem, Tabbar},
  data() {
    return {
      datas: {}
    }
  },
  async mounted() {
    const $index = await this.$Api.companyContent();
    this.datas = $index.data;
    // console.log(this.datas);
  }
}
</script>
<style lang="less" scoped>
.img-wrap {
  padding: 30px;

  img {
    width: 690px;
    height: 2311px;
  }
}
</style>
<style lang="less">
.about {
  background: #fff;
  position: relative;
}

.about-us {
  img {
    max-width: 100%
  }
}
</style>