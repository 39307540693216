<!-- 授课形式 -->
<template>
  <div class="swiperTeaching1">
    <div class="swiper-container" id="swiperTeaching1">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in teachingListData"
          :key="index"
        >
          <img :src="item.url" class="bookImg" />
          <!-- <p class="bookName">
          {{ item.cntname }}
        </p> -->
        </div>
      </div>
      <div class="swiper-pagination-teaching1"></div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";
export default {
  data() {
    return {
      teachingListData: [],
      bookSwiper: [
        
      ],
    };
  },
  created() {
    // rem适配
    const setFontSize = () => {
      var html = document.documentElement;
      var windowWidth = html.clientWidth;
      if (windowWidth <= 750) {
        html.style.fontSize = windowWidth / 7.5 + "px";
      } else {
        html.style.fontSize = "100px";
      }
    };
    window.onresize = setFontSize;
    setFontSize();
  },
  mounted() {
    this.$Api.teachingList().then((res) => {
      this.teachingListData = res.data.rotationMapInfo;
      setTimeout(() => {
        new Swiper("#swiperTeaching1", {
          direction: "horizontal",
          speed: 600,
          loop: true,
          autoplay: false,
          observer: true,
          observeParents: true,
          effect: "coverflow",
          slidesPerView: "auto",
          centeredSlides: true,
          slideToClickedSlide: true,
          initialSlide: 0,
          coverflowEffect: {
            rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
            stretch: 12, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
            depth: 20, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
            modifier: 5,
            slideShadows: false, //开启slide阴影。默认 true。
          },
          pagination: {
            el: ".swiper-pagination-teaching1",
          },
        });
      }, 100);
    });
  },
};
</script>

<style lang="less">
.swiperTeaching1 {
  padding:  0 0.51rem;
  margin-bottom: .6rem;
  #swiper1 {
    width: 100%;
    /* height: 340px; */
    /* margin-top: 36px; */
  }
  .swiper-pagination-teaching1 {
    width: 100%;
    margin-top: 0.3rem;
    justify-content: center;
    display: flex;
    --swiper-pagination-color: #00ff33; /* 两种都可以 */
     .swiper-pagination-bullet{
      margin-right: .1rem;
      width: .13rem;
      height: .13rem;
    }
    .swiper-pagination-bullet-active{
      background: #0DD280;
    }
  }
  .swiper-slide {
    width: 2.78rem !important;
    height: 3.54rem;
    font-size: 14px;
    text-align: center;
    line-height: 80px;
    border-radius: 8px;
    position: relative;
  }
  .bookImg {
    width: 100% !important;
    height: 100%;
    border-radius: .1rem;
  }
  .bookName {
    font-size: 14px;
    width: 100%;
    display: none;
    margin-top: -55px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .swiper-slide-active .bookName {
    display: block;
  }
}
</style>
