<template>
  <div class="sywap-select" :class='open?"open":""'>
    <div class="input flex-align" @click.stop="open = !open">
      <span>{{label}}</span>
      <img class='icon' src="@img/icon-select.png" alt="">
    </div>
    <div class="modal" v-if="open">
      <ul>
        <li v-for="(d,i) in datas" :key="i" @click="handleChange(d.value)">{{d.label}}</li>
        <li v-if="datas.length === 0">暂无数据</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    datas: {
      default: ()=>{
        return []
      },
    },
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    document.addEventListener("click", () => {
      this.open = false;
    });
  },
  computed: {
    label() {
      const filter = this.datas.filter((i) => i.value === this.value);
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
  },
  methods: {
    handleChange(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style scoped lang="less">
.sywap-select {
  position: relative;
  font-size: 26px;
  color: @black-color2;

  .input {
    height: 63px;
    font-weight: 500;
    padding: 0 18px 0 30px;
    justify-content: space-between;
    background: #f0f0f0;
    border-radius: 10px;
  }
  .modal {
    width: 100%;
    position: absolute;
    background: #f0f0f0;
    top: 60px;
    border-radius: 0 0 10px 10px;
    padding: 10px 30px;
    background: #f0f0f0;
    z-index: 9;

    max-height: 500px;
    overflow-y: auto;
    li {
      padding: 15px 0;
    }
  }
  &.open {
    .icon {
      transition: all 0.2s linear;
      transform: rotate(180deg);
    }
  }
  .icon {
    width: 21px;
    height: 13px;
  }
}
</style>