<template>
  <div class="school">
    <div class="schoolTabs scrollbar" ref="messagesContainer">
      <li
      ref="tabLi"
        :class="activeClass == index ? 'activeClass' : 'itemClass'"
        @click="tabClick(index)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item.name }}
      </li>
    </div>
    <!-- <van-icon name="arrow-left"/> -->
    <div class="swiper-container" id="school">
      <!-- <div class="swiper-paginationSchool"></div> -->
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="slideInside">
            <img :src="item.src" />
            <div>
              <p>{{ item.school }}</p>
              <!-- <div v-html="item.detail"></div> -->
              <!-- <span v-html="item.detail"></span> -->
              <span>地址：{{ item.address }}</span>
              <span>电话：{{ item.phone }}</span>
              <!-- <span>地址：徐州市动龙区文峰5楼龙区文峰5楼</span>
              <span>电话：15162494322</span> -->
            </div>
          </div>
        </div>
      </div>
      <div
        class="swiper-button-prev btn swiper-button-black"
        @click="prev"
      ></div>
      <div
        class="swiper-button-next btn swiper-button-black"
        @click="next"
      ></div>
      <!-- <van-icon name="arrow" /> -->
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";
export default {
  data() {
    return {
      c_swiper: null,
      activeClass: 0,
      tabList: [
        // { id: 1, name: "北京" },
        // { id: 2, name: "河北" },
        // { id: 3, name: "天津" },
        // { id: 4, name: "上海" },
        // { id: 5, name: "深圳" },
        // { id: 6, name: "广东" },
        // { id: 7, name: "河南" },
        // { id: 8, name: "内蒙古" },
        // { id: 9, name: "青海" },
        // { id: 10, name: "山东" },
      ],
      dataList: [],
    };
  },
  created() {
    // rem适配
    const setFontSize = () => {
      var html = document.documentElement;
      var windowWidth = html.clientWidth;
      if (windowWidth <= 750) {
        html.style.fontSize = windowWidth / 7.5 + "px";
      } else {
        html.style.fontSize = "100px";
      }
    };
    window.onresize = setFontSize;
    setFontSize();
  },
  methods: {
    removeHTMLTag(str) {
      str = str.replace(/<p\/?[^>]*>/g, ""); //去除HTML tag
      str = str.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
      str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行
      str = str.replace(/\s+style="[^"]*"/g,'');
      str = str.replace(/<\/?p[^>]*>/gi,'')
      str = str.replace(/<\/?h2[^>]*>/gi,'')
      str = str.replace(/ /gi, ""); //去掉
      return str;
    },
    escape2Html(str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    tabClick(index) {
      this.activeClass = index;
      this.c_swiper.slideTo(index, 1000, true);
    },
    prev() {
      this.$refs.messagesContainer.scrollLeft =
        this.$refs.tabLi[this.activeClass-1].offsetLeft-20;
    },
    next() {
      this.$refs.messagesContainer.scrollLeft =
        this.$refs.tabLi[this.activeClass+1].offsetLeft-20;
    }
  },
  mounted() {
    this.$Api.branchCampusList().then((res) => {
      res.data.lists.forEach((item) => {
        this.tabList.push({ name: item.city });
        this.dataList.push({
          school: item.schoolLists[0].school,
          src: item.schoolLists[0].src,
          detail: item.schoolLists[0].detail,
          address: item.schoolLists[0].address,
          phone: item.schoolLists[0].phone,
          // detail: this.removeHTMLTag(item.schoolLists[0].detail),
        });
      });
      setTimeout(() => {
        this.c_swiper = new Swiper("#school", {
          direction: "horizontal",
          speed: 600,
          loop: false,
          autoplay: false,
          observer: true,
          observeParents: true,
          effect: "slide",
          slidesPerView: "auto",
          centeredSlides: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          pagination: {
            el: ".swiper-paginationSchool",
            clickable: true,
          },
          on: {
            slideChange: () => {
              this.activeClass = this.c_swiper.activeIndex;
              // if (this.activeClass > 5) {
              //   this.$refs.messagesContainer.scrollLeft =
              //     this.$refs.messagesContainer.scrollLeft + 100;
              // } else {
              //   this.$refs.messagesContainer.scrollLeft =
              //     this.$refs.messagesContainer.scrollLeft - 30;
              // }
            },
          },
        });
      }, 100);
    });
  },
};
</script>

<style lang="less">
.school {
  .scrollbar{
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
  margin-bottom: 0.5rem;
  // padding: 0 0.3rem 0 0.3rem;
  .slideInside {
    width: 3.19rem;
    img {
      width: 3.19rem;
      height: 1.95rem;
      border-radius: .1rem .1rem 0 0;
    }
    p {
      color: #323c32;
      font-size: 0.3rem;
      height: 0.6rem;
    }
    div {
      padding: 0.32rem 0.2rem 0.5rem 0.2rem;
      background: #f9f9f9;
      margin-top: -0.07rem;
      border-radius: 0 0 0.1rem 0.1rem;
      span {
        display: inline-block;
        color: #aaadaa;
        font-size: 0.22rem;
        // padding-bottom: 0.3rem;
        // text-align: justify;
      }
    }
  }
  i {
    width: 0.8rem;
    height: 0.8rem;
    background: #f2f2f2;
    border-radius: 0.8rem;
    text-align: center;
    color: #c6c6c6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
  }
  .schoolTabs {
    overflow: auto;
    display: flex;
    align-items: center;
    height: 0.8rem;
    margin-bottom: 0.26rem;
    ::-webkit-scrollbar{
      display: none;
    }
    li {
      min-width: 1.1rem;
      // text-align: center;
      border-right: 1px solid #ccc;
      list-style: none;
      padding: 0 0 0 0.24rem;
    }
    li:first-child {
      padding-left: 0;
    }
    li:last-child {
      border: none;
      padding-right: 0;
    }
  }
  .itemClass {
    color: #323c32;
    font-size: 0.24rem;
  }
  .activeClass {
    color: #242424;
    font-weight: bold;
    font-size: 0.3rem;
  }
  #school {
    width: 100%;
    margin-top: 0.3rem;
  }
  .swiper-wrapper {
    border-radius: 0.1rem;
  }
  .swiper-paginationSchool {
    width: 100vw;
    margin-bottom: 0.62rem;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow-y: auto;
    .swiper-pagination-bullet {
      //   margin-right: .1rem;
      width: 1.09rem;
      height: 0.33rem;
      text-align: center;
      border-radius: 0;
      color: #323c32;
      background: #fff;
      white-space: nowrap;
      font-size: 0.24rem;
      border-right: 1px solid #323c32;
    }
    .swiper-pagination-bullet-active {
      height: 0.42rem;
      //   background: #00ff33;
      color: #242424;
      background: #fff;
      font-weight: bold;
      font-size: 0.3rem;
    }
  }
  .swiper-slide {
    width: 100%;
    // height: 5rem;
    border-radius: 0.1rem 0.1rem 0 0;
    position: relative;
    border-radius: 0.1rem;
    // padding: 0.52rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bookImg {
    width: 100% !important;
    height: 2.92rem;
  }
}
</style>
