<!-- 创建订单页面 -->
<template>
  <div class="order-container">
    <div class="class-banner">
      <img class="class-banner-picture" :src="classInfo.largePicture" alt="" />
    </div>
    <div class="class-infos">
      <div class="class-title">{{ classInfo.title }}</div>
      <div class="class-price">
        <span>价格:</span>
        <span class="class-real-price">￥{{ showPrice }}元</span>
        <span class="class-origin-price">{{ classInfo.price }}</span>
      </div>
    </div>
    <div ref="classAbout" class="class-about">
      <div v-html="classInfo.about"></div>
    </div>
    <div class="order-btns">
      <div class="order-btns-wrapper">
        <div class="order-btn-price class-real-price">￥{{ showPrice }}元</div>
        <div class="order-btn" @click="submitOrder">提交订单</div>
      </div>
    </div>
  </div>
</template>
<script>
// 解密价格
import { decrypt } from '@/utils/crypto.js'
// 接口
import { getInfoByClassId } from "@/api/create-order.js";

export default {
  name: "CreateOrder",
  data() {
    return {
      price: null,  // 价格
      showPrice: null,  // 展示价格
      classId: null,  // 班级ID
      classInfo: {
        title: "", // 班级名称
        price: "", // 班级价格（原价格）
        smallPicture: "", // 班级banner
        largePicture: "", // 班级banner - 大图
        about: "", // 班级介绍
      },
    };
  },
  created() {
    // 初始化获取价格及班级ID
    const { price, classId } = this.$route.query;
    this.price = price || null
    if(price) {
      const urlPrice = decodeURIComponent(price)
      this.showPrice = parseFloat(decrypt(urlPrice))
    }
    this.classId = classId ? parseInt(classId) : null;
    // 根据classId 获取页面信息
    this.getInfoByClassIdFn(this.classId);
  },
  methods: {
    // 根据classId 获取页面信息
    async getInfoByClassIdFn(classId) {
      try {
        if (classId) {
          const res = await getInfoByClassId(classId);
          if (res?.data?.code === 0) {
            const resData = res?.data?.data;
            this.classInfo.title = resData?.title;
            this.classInfo.price = resData?.price;
            this.classInfo.smallPicture = resData?.smallPicture;
            this.classInfo.largePicture = resData?.largePicture;
            this.classInfo.about = resData?.about;
            console.log(this.classInfo, "this.classInfo------------->");

            // 设置内容图片的样式
            this.$nextTick(() => {
              const img =  this.$refs.classAbout.querySelector('img')
              const p = this.$refs.classAbout.querySelector('p')
              const div = this.$refs.classAbout.querySelector('div')
              div && (div.style.width = "100%")
              p && (p.style.width = "100%")
              img && (img.style.width = "100%")
            })
          }
        }
      } catch (error) {
        console.log("获取页面信息错误：", error);
      }
    },
    // 提交订单 - 跳转详情页
    submitOrder() {
      this.$router.push({
        name: 'CreateOrderDetail',
        query: {
          price: this.price,
          classId: this.classId,
          classInfo: JSON.stringify(this.classInfo)
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.order-container {
  position: relative;
  height: 100vh;
  background-color: #ffffff;
  overflow-y: scroll;
}
.class-banner-picture {
  width: 100%;
}
.class-infos {
  font-size: 1.2rem;
  padding: 0.75rem;
  box-sizing: border-box;
}
.class-title {
  margin-bottom: 0.5rem;
}
.class-price {
  display: flex;
  align-items: center;
}
.class-real-price {
  font-size: 1.4rem;
  margin: 0 0.75rem;
  color: #ff0000;
}
.class-origin-price {
  text-decoration: line-through;
}
.class-about {
  max-width: 100%;
  padding-bottom: 4rem;
}
.order-btns {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 4rem;
  background-color: #FFFFFF;
}
.order-btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #cccccc;
}
.order-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF0000;
    color: #FFFFFF;
    width: 25%;
    height: 100%;
    font-size: 1rem;
}
</style>
