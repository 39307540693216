// hls.js - 用于 video 播放hls格式的视频
import Hls from "hls.js";

export const utils = {
  CDNURL: "https://saiyouprod.oss-cn-beijing.aliyuncs.com", // cdn地址
  VANTURL: "https://img01.yzcdn.cn/vant", // vant 图片地址

  getDropDownValue: function (arr, types = []) {
    // 下拉组件 使用
    arr.forEach((it) => {
      it.text = it[types[0]] || it.value;
      it.label = it[types[0]] || it.value;
      it.value = it[types[1]] || it.id;
    });
  },
};

/**
 * 格式化video, 可以播放 hls 视频
 * @params: self ---> this,
 * @params: currentVideoUrl ---> this.videoUrl,
 * @params: currentVideoRef ---> 'videos', this.$refs.videos
 * */
export const getStream = (self, currentVideoUrl, currentVideoRef, index) => {
  console.log(currentVideoUrl, "非数组 getStream---------->");
  // self = this
  let refDom = self.$refs[currentVideoRef];
  if (Hls.isSupported()) {
    self.hls = new Hls();
    self.hls.loadSource(currentVideoUrl);
    self.hls.attachMedia(refDom);
    self.hls.on(Hls.Events.MANIFEST_PARSED, () => {
      console.log("加载成功------index");
      // 院校库详情 - 非自动播放
      // refDom && refDom.play()
    });
    self.hls.on(Hls.Events.ERROR, (event, data) => {
      console.log("加载失败 -------- index");
    });
  } else if (refDom.canPlayType("application/vnd.apple.mpegurl")) {
    refDom.src = currentVideoUrl;
    refDom.addEventListener("loadedmetadata", function () {
      refDom && refDom.play();
    });
  } else {
    // 火狐兼容性提示
    const explorer = window.navigator.userAgent;
    if (explorer.indexOf("Firefox") >= 0) {
      this.$message.warning(
        '火狐浏览器不支持当前视频格式，请安装"Native HLS"插件！'
      );
    }
  }
};

// 判断hls视频地址是否过期，【404 则重新获取视频地址】
export const checkHlsUrl = (url) => {
  try {
    return new Promise((resolve, reject) => {
      // 测试链接连通性, 主要检测404错误
      // 由于AJAX通常无法区分404和跨域问题
      // 所以只能用script 或者 link标签
      // link比script更容易捕获错误
      // 判断浏览器类型

      let dom;
      // if (explorer.indexOf("Chrome") >= 0 || explorer.indexOf("MSIE") >= 0) {
      //   // 谷歌或IE
      //   dom = document.createElement("link");
      //   dom.href = url;
      //   dom.rel = "stylesheet";
      //   document.head.appendChild(dom);
      // } else if (
      //   explorer.indexOf("Firefox") >= 0 ||
      //   explorer.indexOf("Safari") >= 0
      // ) {
      //   // 火狐
      //   // dom = document.createElement('script')
      //   // dom.src = url
      //
      //   dom = window["document"].createElement("script");
      //   dom.setAttribute("type", "text/JavaScript");
      //   dom.setAttribute("src", url);
      //   window["document"].head.appendChild(dom);
      // }

      // wap 端不进行浏览器类型检测
      dom = document.createElement("link");
      dom.href = url;
      dom.rel = "stylesheet";
      document.head.appendChild(dom);

      dom.onload = function () {
        document.head.removeChild(dom);
        resolve("地址正确");
      };
      dom.onerror = function () {
        reject("地址错误");
      };
    });
  } catch (error) {
    console.log("检测hls视频地址404错误：", error);
  }
};
