<!-- 创建订单详情页面 -->
<template>
  <div class="order-detail-container">
    <div class="detail-class-info">
      <div class="detail-notice">
        订单已提交，请在48小时内完成支付！逾期订单将被取消.
      </div>
      <div class="detail-info-wrapper">
        <h4>班级信息</h4>
        <div class="detail-card">
          <img :src="classInfo.smallPicture" alt="" />
          <div class="detail-card-info">
            <span>{{ classInfo.title }}</span>
            <span>￥{{ price }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pay-times">
      <h4>分期支付</h4>
      <div class="pay-times-select">
        <van-radio-group v-model="paymentValue">
          <van-cell-group>
            <van-cell clickable @click="paymentValue = 'alipay'">
              <template #title>
                <div class="pay-content">
                  <img
                    class="paylogo"
                    src="@/assets/images/alipay.png"
                    alt=""
                  />
                  <span>支付宝支付</span>
                </div>
              </template>
              <template #right-icon>
                <van-radio name="alipay" />
              </template>
            </van-cell>
            <van-cell
              v-show="isHuaBei"
              clickable
              @click="paymentValue = 'huabeifenqi'"
            >
              <template #title>
                <div class="pay-content">
                  <img
                    class="paylogo"
                    src="@/assets/images/huabei.png"
                    alt=""
                  />
                  <div class="huabei-notice">
                    <span>花呗分期支付</span>
                    <span class="huabei-notice-sub"
                      >推荐有支付宝花呗的用户使用</span
                    >
                  </div>
                </div>
              </template>
              <template #right-icon>
                <van-radio name="huabeifenqi" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <div
          v-show="paymentValue === 'huabeifenqi' && isHuaBei"
          class="huabei-wrapper"
        >
          <van-radio-group v-model="paymentTimes">
            <van-radio name="three" icon-size="16px">
              <div class="pay-details">
                <span class="pay-details-info"
                  >￥{{ paymentTimesInfo.three.eachPrin }} *
                  {{ paymentTimesInfo.three.stage }} 期</span
                >
                <span class="pay-details-poundage">{{
                  parseFloat(paymentTimesInfo.three.poundage) == 0
                    ? "0手续费"
                    : `含手续费：${paymentTimesInfo.three.poundage}元/期`
                }}</span>
              </div>
            </van-radio>
            <van-radio name="six" icon-size="16px">
              <div class="pay-details">
                <span class="pay-details-info"
                  >￥{{ paymentTimesInfo.six.eachPrin }} *
                  {{ paymentTimesInfo.six.stage }} 期</span
                >
                <span class="pay-details-poundage">{{
                  parseFloat(paymentTimesInfo.six.poundage) == 0
                    ? "0手续费"
                    : `含手续费：${paymentTimesInfo.six.poundage}元/期`
                }}</span>
              </div>
            </van-radio>
            <van-radio name="twelve" icon-size="16px">
              <div class="pay-details">
                <span class="pay-details-info"
                  >￥{{ paymentTimesInfo.twelve.eachPrin }} *
                  {{ paymentTimesInfo.twelve.stage }} 期</span
                >
                <span class="pay-details-poundage">{{
                  parseFloat(paymentTimesInfo.twelve.poundage) == 0
                    ? "0手续费"
                    : `含手续费：${paymentTimesInfo.twelve.poundage}元/期`
                }}</span>
              </div>
            </van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="order-btns">
      <div class="order-btns-wrapper">
        <div class="order-btn-price class-real-price">
          <span>￥{{ price }}</span>
          <span class="reduce-price">共省￥{{ classInfo.price - price }}</span>
        </div>
        <div class="order-btn" @click="submitPaymentOrder">提交订单</div>
      </div>
    </div>

    <!-- 提交与跳转支付宝的表单 -->
    <div class="pay-form">
      <form ref="payForm" :action="formAction" :method="formMethod">
        <input
          v-for="(value, key, index) in formParams"
          :key="index"
          type="hidden"
          :name="key"
          :value="value"
        />
        <input type="submit" />
      </form>
    </div>
  </div>
</template>
<script>
// md5 加密
import md5 from 'js-md5'
// 解密价格
import { decrypt } from '@/utils/crypto.js'
// 接口
import { getPaymentTimes, getHBpayParams } from "@/api/create-order.js";

export default {
  name: "CreateOrderDetail",
  data() {
    return {
      price: null, // 价格
      classId: null, // 班级ID
      classInfo: {
        title: "", // 班级名称
        price: 0, // 班级价格（原价格）
        smallPicture: "", // 班级banner
      }, // 班级信息
      paymentTimesInfo: {
        six: {
          stage: null, // 分期数
          poundage: null, // 每期手续费
          eachPrin: null, // 每期支付金额
        },
        three: {
          stage: null, // 分期数
          poundage: null, // 每期手续费
          eachPrin: null, // 每期支付金额
        },
        twelve: {
          stage: null, // 分期数
          poundage: null, // 每期手续费
          eachPrin: null, // 每期支付金额
        },
      }, // 花呗分期信息
      isHuaBei: true, // 是否可以使用花呗分期
      paymentValue: "huabeifenqi", // 选中的支付方式
      paymentTimes: "three", // 花呗分期数
      isInterestFee: 1, // 是否免费
      successUrl: "", // 支付成功后的跳转
      formAction: "",
      formMethod: "",
      formParams: null,
    };
  },
  created() {
    // 支付成功后的跳转页
    this.successUrl = `${process.env.VUE_APP_ORG_URL}/order-success`
    // 初始化获取价格及班级ID
    const { price, classId, classInfo } = this.$route.query;
    if(price) {
      const urlPrice = decodeURIComponent(price)
      this.price = parseFloat(decrypt(urlPrice))
    }
    this.classId = classId ? parseInt(classId) : null;
    this.classInfo = classInfo
      ? JSON.parse(classInfo)
      : {
          title: "",
          price: 0,
          smallPicture: "",
        };
    // 获取花呗分期
    this.getPaymentTimesFn(this.price);
  },
  methods: {
    // 获取花呗分期
    async getPaymentTimesFn(totalPrice) {
      // 实付价格
      try {
        if (totalPrice) {
          const res = await getPaymentTimes(totalPrice);
          if (res?.data?.code === 0) {
            const resData = res?.data?.data;
            this.paymentTimesInfo = resData[0]
              ? resData[0]
              : {
                  six: {
                    stage: null, // 分期数
                    poundage: null, // 每期手续费
                    eachPrin: null, // 每期支付金额
                  },
                  three: {
                    stage: null, // 分期数
                    poundage: null, // 每期手续费
                    eachPrin: null, // 每期支付金额
                  },
                  twelve: {
                    stage: null, // 分期数
                    poundage: null, // 每期手续费
                    eachPrin: null, // 每期支付金额
                  },
                };
          } else {
            // 不能使用花呗分期
            this.isHuaBei = false;
            this.paymentValue = "alipay";
          }
        }
      } catch (error) {
        console.log("获取花呗分期错误：", error);
      }
    },
    // 使用支付宝或花呗支付
    async submitPaymentOrder() {
      try {
        // 获取支付参数
        let params = {
          payment: this.paymentValue, // 支付方式
          totalPrice: this.price, // 实际支付价格
          classroomId: this.classId, // 班级id
          successUrl: this.successUrl, // 支付成功后的返回页
          sign: md5(this.classId + '' + this.price + '' +process.env.VUE_APP_ORDER_KEY) // 生效信息签名
        };
        if (this.paymentValue === "huabeifenqi") {
          let poundage = this.paymentTimesInfo[this.paymentTimes].poundage;
          this.isInterestFee = parseInt(poundage) == 0 ? 1 : 0;
          // 花呗分期支付
          if (this.paymentTimes === "three") {
            params.hbfq = 3; // 分期数
          } else if (this.paymentTimes === "six") {
            params.hbfq = 6; // 分期数
          } else if (this.paymentTimes === "twelve") {
            params.hbfq = 12; // 分期数
          }
          params.isInterestFee = this.isInterestFee; // 是否免费
        }
        const res = await getHBpayParams(params);
        console.log(res, "res--ddd----->");
        if (res?.data?.code === 0) {
          const resData = res?.data?.data;
          this.formAction = resData?.action;
          this.formMethod = resData?.method;
          this.formParams = resData?.params;

          // 等待form加载完成后提交
          this.$nextTick(() => {
            this.submitPayForm();
          });
        } else {
          this.$message.error(res?.data?.message || "支付失败");
        }
      } catch (error) {
        console.log("跳转支付宝支付错误：", error);
      }
    },
    // 手动提交表单
    submitPayForm() {
      // 获取表单DOM元素
      const form = this.$refs.payForm;
      // 提交表单
      form.submit();
    },
  },
};
</script>
<style lang="less" scoped>
.order-detail-container {
  position: relative;
  height: 100vh;
  overflow: scroll;
  background-color: #efefef;
}
.detail-class-info {
  font-size: 0.7rem;
  background-color: #ffffff;
  padding: 0.5rem;
  box-sizing: border-box;
}
.detail-notice {
  border: 1px solid #eebe77;
  color: #eebe77;
  background-color: #faecd8;
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: 4px;
}
.detail-info-wrapper h4 {
  font-size: 0.9rem;
  font-weight: bold;
  padding: 1rem 0 0.5rem;
  border-bottom: 1px solid #efefef;
}
.detail-card {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}
.detail-card img {
  width: 25%;
}
.detail-card-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 4rem;
  flex: 1;
  margin-left: 0.5rem;
  font-size: 1rem;
  color: #bababa;
}
.order-btns {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4rem;
  background-color: #ffffff;
}
.order-btns-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #cccccc;
}
.order-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0000;
  color: #ffffff;
  width: 25%;
  height: 100%;
  font-size: 1rem;
}
.class-real-price {
  font-size: 1.4rem;
  margin: 0 0.75rem;
  color: #ff0000;
}
.reduce-price {
  font-size: 0.7rem;
  color: #cccccc;
  margin-left: 0.75rem;
}
.pay-times h4 {
  padding: 1rem 0.5rem;
  font-size: 0.9rem;
}
.pay-form {
  opacity: 0;
}
.pay-times-select {
  box-sizing: border-box;
  padding: 0.5rem;
  background-color: #ffffff;
}
.pay-content {
  display: flex;
  align-items: center;
}
.pay-content img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}
.huabei-notice {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.huabei-notice-sub {
  font-size: 0.6rem;
  color: #cdcdcd;
}
.huabei-wrapper {
  box-sizing: border-box;
  padding: 0.75rem;
}
.pay-details {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  font-weight: bold;
}
.pay-details-poundage {
  font-size: 0.6rem;
  color: #cccccc;
  font-weight: normal;
}
.van-radio {
  margin-bottom: 0.5rem;
}
</style>
