import Vue from 'vue'

// 引入Element UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 引入Vant
import Vant from "vant";
import "vant/lib/index.css";

import App from './App.vue'
import router from './router'
import store from './store'

import { Message } from 'element-ui'

import "./core/use"

// 阿里云前端监控代码，仅用于线上环境 （alife-logger）
if (process.env.NODE_ENV === 'production') {
  const BrowserLogger = require('alife-logger');
  const __bl = BrowserLogger.singleton({ pid: "ga4vr5iygy@ad6c24c31374ca4", appType: "web", imgUrl: "https://arms-retcode.aliyuncs.com/r.png?", sendResource: true, enableLinkTrace: true, behavior: true });
  console.log(__bl, 'wap----阿里云前端监控---------->')
}


Vue.use(ElementUI)
Vue.use(Vant)

Vue.config.productionTip = false
Vue.prototype.$message = Message


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
