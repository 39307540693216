<!-- 视频播放组件 -->
<template>
  <div>
    <van-overlay
        :show="isShowVideoModal"
        class="overlay-container"
        @click="stopModalVideo"
    >
      <div class="wrapper" @click.stop>
        <video
            v-for="(item, index) in modalVideoList"
            v-show="item === currentVideoUrl"
            :key="index"
            :ref="'overlayVideo' + index"
            :poster="currentCoverUrl"
            class="overlay-video"
            controls
        ></video>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// hls.js - 用于 video 播放hls格式的视频
import Hls from "hls.js";

export default {
  data() {
    return {
      isShowVideoModal: false, // 视频弹框展示
      modalVideoList: [], // 弹框视频地址列表
      currentVideoUrl: "", // 当前video的地址
      currentCoverUrl: "", // 当前视频的封面地址
    };
  },
  mounted() {
    // 赛优简介 - 轮播视频 【未使用】
    this.$bus.$on("playVideo", this.handlePlayBriefVideo);
    // 媒体采访视频
    this.$bus.$on("playMedia", this.handlePlayMediaVideo);
  },
  beforeDestroy() {
    // 销毁hls对象
    this.modalVideoList.forEach((item, index) => {
      if (this[`modalHls${index}`]) {
        this.$refs[`overlayVideo${index}`][0] &&
        this.$refs[`overlayVideo${index}`][0].pause();
        this[`modalHls${index}`].destroy();
        this[`modalHls${index}`] = null;
      }
    });
  },
  methods: {
    // 赛优简介 - 视频播放 【未使用】
    handlePlayBriefVideo({title, pic, src}) {
      // 视频格式的处理
      this.handleHls(src);
    },
    // 媒体采访 - 视频播放
    handlePlayMediaVideo(url) {
      // 视频格式的处理
      this.handleHls(url.playURL, url.coverURL);
    },
    stopModalVideo() {
      // 关闭弹框
      this.isShowVideoModal = false;
      this.modalVideoList.forEach((item, index) => {
        this.$refs[`overlayVideo${index}`][0] &&
        this.$refs[`overlayVideo${index}`][0].pause();
      });
      this.currentVideoUrl = "";
      // this.$refs.overlayVideo.pause();
    },
    // 处理url地址，使video绑定hls格式
    handleHls(videoUrl, imgUrl) {
      // 显示弹框
      this.isShowVideoModal = true;
      // 当前的url地址
      this.currentVideoUrl = videoUrl;
      // 视频封面地址
      this.currentCoverUrl = imgUrl;
      // url是否存在于数组中
      let hasVideoUrl =
          this.modalVideoList.indexOf(this.currentVideoUrl) !== -1;
      if (this.currentVideoUrl && !hasVideoUrl) {
        // 不存在则加入到数组中
        this.modalVideoList.push(this.currentVideoUrl);
        // 格式化video元素，使其绑定hls
        let index = this.modalVideoList.indexOf(this.currentVideoUrl); // 当前地址在数组中的index
        this.$nextTick(() => {
          this.getModalHlsStream(index);
        });
      } else if (this.currentVideoUrl && hasVideoUrl) {
        // 显示弹框
        this.courseVideoShow = true;
        let index = this.modalVideoList.indexOf(this.currentVideoUrl); // 当前地址在数组中的index
        // 存在则播放视频
        this.$refs[`overlayVideo${index}`][0] &&
        this.$refs[`overlayVideo${index}`][0].play();
      } else if (!this.currentVideoUrl) {
        // 地址不存在则报错；地址存在且已存在于数组中，会直接播放
        this.$message({
          type: "error",
          message: "视频地址不存在",
        });
        console.log("视频地址不存在");
      }
    },
    // 弹框视频 - hls 绑定 video 标签
    getModalHlsStream(index) {
      const refElement = this.$refs[`overlayVideo${index}`][0];
      let currentVideoUrl = this.modalVideoList[index];
      if (Hls.isSupported()) {
        if (!this[`modalHls${index}`]) {
          this[`modalHls${index}`] = new Hls();
        }
        this[`modalHls${index}`].loadSource(currentVideoUrl);
        this[`modalHls${index}`].attachMedia(refElement);
        this[`modalHls${index}`].on(Hls.Events.MANIFEST_PARSED, () => {
          // console.log("加载成功------index");
          refElement && refElement.play();
        });
        this[`modalHls${index}`].on(Hls.Events.ERROR, (event, data) => {
          // console.log("加载失败 -------- index");
        });
      } else if (refElement.canPlayType("application/vnd.apple.mpegurl")) {
        refElement.src = currentVideoUrl;
        refElement.addEventListener("loadedmetadata", function () {
          refElement && refElement.play();
        });
      } else {
        // 火狐兼容性提示
        const explorer = window.navigator.userAgent;
        if (explorer.indexOf("Firefox") >= 0) {
          this.$message.warning(
              '火狐浏览器不支持当前视频格式，请安装"Native HLS"插件！'
          );
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.overlay-container {
  padding-bottom: 100px;
}

.overlay-video {
  max-width: 80%;
  margin: 0 auto;
  display: block;
}
</style>
